import axios from 'axios';
import authHeader from './auth-header';
import {
    Location
} from '../utils/config';

class ApiService {
    getDataLocation(tenantId) {
        return axios.get(Location+'?tenant_id='+tenantId, {
         headers: 
            {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                var data = response.data;
                return data;
        });
    }
    
}
export default new ApiService();