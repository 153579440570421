<template>
    <div class="bg-white pt-10">
      
     
      <section class="banner_category mx-auto w-full p-10 lg:p-20 ">
        <div class="rounded-lg p-10 lg:h-[435px]" style="background: linear-gradient(0deg, #F3A323 0%, #F3A323 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.42) 100%);">
          <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 items-center">
            <img src="../assets/images/Kiri.svg" alt="">
            <div class="text-4xl lg:text-5xl font-semibold text-white text-center">Trainer</div>
            <img src="../assets/images/Kanan.svg" alt="">
          
          </div>
         </div>
         <div class="category_desc mx-auto w-full mt-10">
          <div class="text-3xl font-semibold font-primary">Trainer</div>
          <p class="mt-10 text-neutral-700 font-normal text-sm font-primary hidden md:block sm:block text-justify">X-Sportainment memiliki Trainer yang telah berpengalaman dibidang nya. Kami yakin dengan trainer yang memiliki kapasitas dan keahlian anda dapat memperoleh manfaat untuk kesehatan, kebugaran serta meningkatkan kebahagian jasmani & rohani anda.</p>

          <div class="mt-10">
            <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5 pt-10">
              <div class="text-center text-[30px] font-semibold leading-9 text-neutral-700 font-primary">Trainer</div>
            </div>
          </div>

          <div class="mt-10 grid gap-4 grid-cols-2 lg:grid-cols-5 justify-center">
            <div  v-for="item in trainers" :key="item.id">
              <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow ">
                <div v-if="item.picture_path != null">
                  <img class="rounded-t-lg" :src="webUrl+item.picture_path"  alt="Sunset in the mountains"/>
                </div>
                <div v-else>
                  <img class="rounded-t-lg" src="../assets/images/trainer.png"  alt="Sunset in the mountains"/>
                </div>
                <div class="p-3">
                    <h5 class="mb-2 text-base font-medium text-black">{{item.person.name}}</h5>
                    <div class="self-stretch text-neutral-700 text-base font-normal leading-normal">
                      List kelas
                    </div>
                    <ul class="list-disc text-sm ml-5"   v-for="val in item.product_prices"
                    v-bind:key="val.id">
                      <li > 
                        {{ val.product.name }}
                      </li>
                    </ul>

                   
                </div>
              </div>
            </div>
          </div>
         </div>

        
        <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoading">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 class="text-center text-white text-xl font-semibold font-primary">Loading</h2>
          <p class="w-1/3 text-center text-white mt-2 font-primary">This may take a few seconds, please don't close this page.</p>
        </div>
      

       

        <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-24 font-primary">
          <div class="mx-auto text-left">
            <p class="text-black tracking-tight font-display text-2xl font-bold">FAQ</p>
          </div>
          <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
            <div>
                <div class="mb-10">
                    <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                        <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                        Proses Booking?
                    </h3>
                    <p class="text-gray-500 dark:text-gray-400">Booking dapat dilakukan secara online dengan membuat akun pada website X-Sportainment kemudian customer melakukan reservasi dan mengisi informasi yang diperlukan.</p>
                </div>
              
            </div>
            <div>
              <div class="mb-10">                        
                <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                    <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                    Proses Pembayaran?
                </h3>
                  <p class="text-gray-500 dark:text-gray-400">Setelah melakukan reservasi, customer akan diarahkan pada pilihan bayar dan mendapatkan kode bayar. Pembayaran dapat melalui Mobile Banking, ATM, atau ecommerce dan retail yang bekerja sama.</p>
              </div>
                
            </div>

            <div>
              <div class="mb-10">                        
                <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                    <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                    Larangan Untuk Kegiatan Bersifat Sara:
                </h3>
                  <p class="text-gray-500 dark:text-gray-400">
                    Pengguna ruangan wajib menjauhi segala bentuk tindakan atau perkataan yang bersifat diskriminatif, merendahkan martabat, atau bersifat pelecehan terhadap suku, agama, ras, dan antar golongan (SARA). 
                  </p>
              </div>
            </div>

            <div>
              <div class="mb-10">                        
                <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                    <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                    Larangan Acara yang Dilarang oleh Hukum:
                </h3>
                  <p class="text-gray-500 dark:text-gray-400">
                    Dilarang keras menyelenggarakan acara yang bertentangan dengan hukum yang berlaku. Hal ini mencakup, namun tidak terbatas pada, perjudian ilegal, kegiatan ilegal, atau segala bentuk acara yang melanggar ketentuan hukum yang berlaku. 
                  </p>
              </div>
            </div>

            <div>
              <div class="mb-10">                        
                <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                    <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                    Larangan Kegiatan Bermuatan politik:
                </h3>
                  <p class="text-gray-500 dark:text-gray-400">
                    Tidak diperbolehkan Melakukan Acara atau kegiatan yang bermuatan poitik.
                  </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
   
</template>
<script>
import AuthService from "@/services/auth.service";
import ApiService from '@/services/api.service';
import productService from "@/services/product.service";
import Pagination from "@/components/Pagination";
import { useRoute } from 'vue-router';
import{ webUrl } from '../utils/config';
export default {
  name: "trainer-page",
  components: {
    Pagination
  },
  data() {
    return {
        isLoading: true,
        tenant_id: "",
        page: 1,
        totalPages: 10,
        total: 10,
        perPage: 8,
        currentPage: 1,
        hasMorePages: true,
        location_id:'',
        locations: [],
        trainers: [],
        webUrl: webUrl,
        
    };
  },
  computed: {
    indexStart() {
      return (this.currentPage - 1) * this.perPage;
    },
    indexEnd() {
      return this.indexStart + this.perPage;
    },
    paginated() {
      
      return this.productPrices.slice(this.indexStart, this.indexEnd);
    },
  },
  async mounted() {
    
    
    const route = useRoute();  
    let router = await route;

    var data = router.query;
    if(data){
      
      // if(data.location_id){
      //     this.location_id = this.$CryptoJS.AES.decrypt(
      //     data.location_id,
      //     "123#$%"
      //   ).toString(this.$CryptoJS.enc.Utf8);
      
      // }

    
    

      AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant_id = data.id;
          this.getLocation(this.tenant_id);
          this.getTrainer(this.tenant_id);
          
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      
    
    }
    
    
  },
  methods:{
    getLocation(tenantId){
        ApiService.getDataLocation(tenantId).then(
        (response) => {
            var data = response.data;
            this.locations = data;
            if(this.location_id == ''){
              this.location_id = this.locations[0].id;
            }
           
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    getTrainer(tenantId){
      productService.getTrainer(tenantId).then(
        (response) => {
          var data = response.data.data;

            this.trainers = data;
            this.isLoading = false;
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    
    pushRoute(Id, totalPoductPrice){
      // console.log(totalPoductPrice);
      if(totalPoductPrice > 0){
        this.$router.push({
          name: "trainer-detail",
          query: {
            location_id: this.location_id,
            vendor_id: Id
          }
        }).then((response) => {
          location.reload();
        });
        
        
      }else{
        this.$router.push({
          name: "book-product",
          query: {
            location_id: this.location_id,
            vendor_id: Id
          }
        }).then((response) => {
          location.reload();
        });
      }

    },
    scrollTop(id){
      var element = document.getElementById(id);
       var top = element.offsetTop;
     
       window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
      
    }
  }
};
</script>