<template>
    <div>
      
        <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoadingPage">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
            <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
        </div>
        <div v-else>
            <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5 pt-20  py-32 mt-10">
              
                <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoading">
                    <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                    <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
                    <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
                </div>
                
                <div class="grid gap-4 md:grid-cols-1 lg:gap-8 xl:grid-cols-3">
                    <div class="flex grid gap-8">
                      <div v-if="product.picture_path != null">
                        <img class="h-auto max-w-full rounded-lg" :src="webUrl+product.picture_path" alt=""> 
                      </div>
                      <div v-else>
                        <img class="h-auto max-w-full rounded-lg" src="../assets/images/fitness-gym.jpg" alt="">
                      </div>
                    </div>
                    
    
                    <div class="max-w-sm font-primary">
                        <div class="px-2">
                            <div class="font-bold text-black text-2xl ">{{selectedProductPrices.name }} : {{selectedProductPrices.item}}</div>
                        </div>
    
                        <div class="px-2 pt-2 pb-2 mt-2">
                           
                            <div >
                              <div class="whitespace-no-wrap text-sm leading-5 text-black-500 mb-2">
                                <div style="width: 100%; height: 100%; padding-left: 16px; padding-right: 24px; justify-content: center; align-items: center; gap: 16px; display: inline-flex">
                                  <img src="../assets/images/time_icon.png" alt="" class="mr-2"/>
                                  <div style="flex: 1 1 0; align-self: stretch; flex-direction: column; justify-content: center; align-items: flex-start; display: inline-flex">
                                      <div style="align-self: stretch; color: #404040; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 16px; word-wrap: break-word">Tersedia pada: </div>
                                      <span v-if="selectedProductPrices.mon == 1 && selectedProductPrices.tue == 1 && selectedProductPrices.wed == 1 && selectedProductPrices.thu == 1 && selectedProductPrices.fri == 1 && selectedProductPrices.sat == 1 && selectedProductPrices.sun == 1">
                                        <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word">Full Day</div>
                                      </span>
                                      <span v-if="selectedProductPrices.mon == 1">
                                        <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word">
                                            Senin<br>
                                            {{ selectedProductPrices.mon_started_time_at }} - {{ selectedProductPrices.mon_ended_time_at }}
                                        </div>
                                      </span>
                                      <span v-if="selectedProductPrices.tue == 1">
                                        <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word  py-2">
                                            Selasa<br>
                                            {{ selectedProductPrices.tue_started_time_at }} - {{ selectedProductPrices.tue_ended_time_at }}
                                        </div>
                                      </span>
                                      <span v-if="selectedProductPrices.wed == 1">
                                        <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word  py-2">
                                            Rabu<br>
                                            {{ selectedProductPrices.wed_started_time_at }} - {{ selectedProductPrices.wed_ended_time_at }}
                                        </div>
                                      </span>
                                      <span v-if="selectedProductPrices.thu == 1">
                                        <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word  py-2">
                                            Kamis<br>
                                            {{ selectedProductPrices.thu_started_time_at }} - {{ selectedProductPrices.thu_ended_time_at }}
                                        </div>
                                      </span>
                                      <span v-if="selectedProductPrices.fri == 1">
                                        <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word  py-2">
                                            Jumat<br>
                                            {{ selectedProductPrices.fri_started_time_at }} - {{ selectedProductPrices.fri_ended_time_at }}
                                        </div>
                                      </span>
                                      <span v-if="selectedProductPrices.sat == 1">
                                        <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word  py-2">
                                            Sabtu<br>
                                            {{ selectedProductPrices.sat_started_time_at }} - {{ selectedProductPrices.sat_ended_time_at }}
                                        </div>
                                      </span>
                                      <span v-if="selectedProductPrices.sun == 1">
                                        <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word  py-2">
                                            Minggu<br>
                                            {{ selectedProductPrices.sun_started_time_at }} - {{ selectedProductPrices.sun_ended_time_at }}
                                        </div>
                                      </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="whitespace-no-wrap text-sm leading-5 text-black-500 mb-2">
                              <div style="width: 100%; height: 100%; padding-left: 16px; padding-right: 24px; justify-content: center; align-items: center; gap: 16px; display: inline-flex">
                                <img src="../assets/images/icon_location.png" alt="" class="mr-2"/>
                                <div style="flex: 1 1 0; align-self: stretch; flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
                                    <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word">{{location.name}}</div>
                                </div>
                            </div>
                            </div>
                            <div class="whitespace-no-wrap text-sm leading-5 text-black-500">
                              <div style="width: 100%; height: 100%; padding-left: 16px; padding-right: 24px; justify-content: center; align-items: center; gap: 16px; display: inline-flex">
                                <img src="../assets/images/payments.png" alt="" class="mr-2"/>
                                <div style="flex: 1 1 0; align-self: stretch; flex-direction: column; justify-content: center; align-items: flex-start; display: inline-flex">
                                    <div style="align-self: stretch; color: #404040; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 24px; word-wrap: break-word">Rp {{ selectedProductPrices.price ? formatPrice(selectedProductPrices.price) : 0 }} / <span v-if="selectedProductPrices.term == 'no term'">Session</span> <span v-else>{{selectedProductPrices.term}}</span></div>
                                </div>
                            </div>
                             
                             
                          </div>
                        </div>

                        <div class="px-2 pt-5 pb-2 mt-5" v-if="product.desc">
                            <div class="text-black text-base">Deskripsi</div>
    
                            <div class="pt-2">
                                <p class="text-black font-normal text-justify`">
                                    {{ product.desc }}
                                </p>
                            </div>
                        </div>
    
                    
                    </div>
                    
                    <div class="w-full max-w-lg ">
                      <div class="p-4 bg-gray-200 border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-200 dark:border-gray-200">
                   
                        <h5 class="text-base font-bold dark:text-black text-center">Pilih Tanggal</h5>

                           

                        <div class="mt-4">
                            <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Mulai Tanggal</label>
                            <input  datepicker  type="date" me class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" v-model="form.started_at"  >
                        </div>

                      
                        <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"  v-if="complimentary.length > 0">
                        

                        <div class="mt-4" v-if="complimentary.length > 0">
                            <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Package</label>
                            <select v-model="selectedComplimentary"  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"  @change="setAvailableComplimentary()">
                            <option :value="null" selected>Pilih package</option>
                            <option v-for="item in complimentary" v-bind:value="{id: item.id, quantity: item.remaining_quantity}"  :key="item.id">{{ item.complimentary_name }}</option>
                            </select>
                        </div>

                        <div class="mt-4" v-if="customer_complimentary_id">
                            <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Penggunaan package </label>
                            <input v-model="total_use_of_complimentary" type="number" min="0" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Penggunaan Complimentary" @change="countPrice()" :disabled="total_available_complimentary == 0 ? true : false">
                            <div
                            class="valid-feedback mt-2"
                            style="display: block; color: #D32923"
                            >
                            Sisa Package bulan ini
                            {{ total_available_complimentary }}
                            {{selectedProductPrices.term == 'hour' ? 'Jam' : selectedProductPrices.term == 'year' ? 'Tahun' : selectedProductPrices.term == 'day' ? 'Hari' : selectedProductPrices.term == 'full day' ? 'Satu Hari' : term == 'half day' ? 'Setengah Hari' : selectedProductPrices.term == 'no term' ? '' : 'Bulan'}}
                            </div>
                        </div>
    
    
                          
                       
                      </div>
                     
                      <div class="p-4 bg-gray-200 border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-200 dark:border-gray-200 mt-2" v-if="this.product.id != null">
    
                        <div class="m-auto flex justify-between items-center">
                            <div class="ml-2 text-sm">
                                <label for="helper-radio" class="font-normal dark:text-black">Subtotal</label>
                            </div>
                            <div class="flex items-center h-5 mt-2 mb-2">
                              <p class=" text-base">Rp {{ formatPrice(total_price) }}</p>
                            </div>
                          
                        </div>

                        <div class="m-auto flex justify-between items-center" v-if="discount > 0">
                          <div class="ml-2 text-sm">
                              <label for="helper-radio" class="font-normal dark:text-black">Discount</label>
                          </div>
                          <div class="flex items-center h-5 mt-2 mb-2">
                            <p class=" text-base">Rp {{ formatPrice(discount) }}</p>
                          </div>
                        </div>

                        <div class="m-auto flex justify-between items-center">
                            <div class="ml-2 text-sm">
                                <label for="helper-radio" class="font-normal dark:text-black">Tax</label>
                            </div>
                            <div class="flex items-center h-5 mb-2">
                              <p class=" text-base">Rp {{ formatPrice(form.total_tax) }}</p>
                            </div>
                          
                        </div>
                        <div class="m-auto flex justify-between items-center mt-6">
                            <div class="ml-2 text-sm">
                                <label for="helper-radio" class="font-bold dark:text-black">Grandtotal</label>
                            </div>
                            <div class="flex items-center h-5 mb-2">
                              <p class="font-bold text-base">Rp {{ formatPrice(grand_total) }}</p>
                            </div>


                          
                        </div>

                        <div class="mt-4">
                          <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Kode Promo</label>
                          <div class="relative mb-2 flex flex-wrap items-stretch">
                            <input
                            v-model="form.promotion_code"
                              type="text"
                              class="relative m-0 block w-[1px] min-w-0 flex-auto bg-white border border-gray-300 text-gray-900 text-sm  rounded-l focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Kode Promo"
                              aria-label="Kode Promo"
                              aria-describedby="basic-addon2" />
                              <button class="flex items-center whitespace-nowrap rounded-r  bg-red-700 hover:bg-700 text-white font-normal px-3 py-[0.25rem]  leading-[1.6] text-md "
                              @click="getPromotionCode(form.promotion_code)"
                              >
                                Gunakan Kode
                            </button>
                            
                          </div>
                          <button class="flex items-center whitespace-nowrap rounded-r  bg-neutral-700 hover:bg-700 text-white font-normal px-3 py-[0.25rem]  leading-[1.6] text-md "
                          @click="clearPromotionCode()"
                          >
                            Hapus Kode
                          </button>
                      </div>

                        <div class="m-auto flex justify-between items-center mt-5">
                            <button class="bg-amber-500 hover:bg-700 text-zinc-800 text-center text-zinc-800 text-base font-medium leading-normal py-4 px-4 rounded text-sm mr-1 mb-2 w-full" @click="addProduct" :disabled="isDisable" :class="isDisable == true ? 'bg-gray-300' : ''">
                                Book Now
                            </button>
                        </div>
                      
                  
                      </div>
                    </div>
                   
                   
    
                </div>
            </div>

            <div class="mx-auto  bg-zinc-900 mt-10 font-primary">
              
              <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-10">
                <div class="mx-auto text-center">
                  <p class="text-white tracking-tight font-display text-3xl font-bold text-center">Fasilitas</p>
                </div>
                <div class="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-4 mt-10">
                  <div>
                      <img class="h-auto max-w-full rounded-lg" src="../assets/images/card.png" alt="">
                  </div>
                  <div>
                      <img class="h-auto max-w-full rounded-lg" src="../assets/images/card1.png" alt="">
                  </div>
                  <div>
                      <img class="h-auto max-w-full rounded-lg" src="../assets/images/card2.png" alt="">
                  </div>
                </div>
                <div class="mx-auto text-center py-5">
                  <p class="text-white font-normal">Fasilitas-fasilitas pendukung yang disediakan oleh X-Sportainment
                    untuk para pelanggan.</p>
                </div>
                <div class="grid grid-cols-1 gap-10 md:gap-54 md:grid-cols-3 py-10">
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_waiting_room.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Ruang Tunggu</div>
                  </div>
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_shower_room.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Shower Room</div>
                  </div>
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_toilet.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Toilet</div>
                  </div>
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_room.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Ruang Ganti</div>
                  </div>
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_locker_room.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Locker</div>
                  </div>
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_ripstick.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Ripstick</div>
                  </div>
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_ripstick.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Free Weight</div>
                  </div>
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_room.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Matras</div>
                  </div>
                  <div class="h-32 p-6 bg-zinc-800 rounded-2xl border border-stone-200 flex-col justify-start items-center gap-4 inline-flex">
                    <img src="../assets/images/icon_charge.png" alt="" >
                      <div class="text-neutral-100 text-base font-semibold leading-normal">Charging</div>
                  </div>
          
                </div>
              </div>
            </div>

           

            <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-24 font-primary">
              <div class="mx-auto text-left">
                <p class="text-black tracking-tight font-display text-2xl font-bold">FAQ</p>
              </div>
              <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
                <div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                            <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                            Proses Booking?
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400">Booking dapat dilakukan secara online dengan membuat akun pada website X-Sportainment kemudian customer melakukan reservasi dan mengisi informasi yang diperlukan.</p>
                    </div>
                  
                </div>
                <div>
                  <div class="mb-10">                        
                    <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                        <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                        Proses Pembayaran?
                    </h3>
                      <p class="text-gray-500 dark:text-gray-400">Setelah melakukan reservasi, customer akan diarahkan pada pilihan bayar dan mendapatkan kode bayar. Pembayaran dapat melalui Mobile Banking, ATM, atau ecommerce dan retail yang bekerja sama.</p>
                  </div>
                    
                </div>
              </div>
            </div>
        </div>
    </div>
</template>
<style>
.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

</style>

<script>
import userService from "@/services/user.service";
import AuthService from "@/services/auth.service";
import productService from "@/services/product.service";
import roomService from "@/services/room.service";
import { useRoute } from 'vue-router';
import moment from 'moment';
import Swal from "sweetalert2";
import{ webUrl } from '../utils/config';
export default {
  name: "product-room-page",
  data() {
    return {
        webUrl: webUrl,
        isAvail: false,
        isLoadingPage:true,
        isLoading:false,
        isLoadingCheck: false,
        tenant:{},
        location_id:'',
        vendor_id:'',
        prices_id:'',
        selectedProductPricesId:'',
        product: {},
        selectedProductPrices:{},
        productPrices:[],
        productIncludes:[],
        includeSalesOrders:[],
        complimentary:[],
        location:{},
        room:{},
        tax:0,
        service_charge:0,
        total_cost:0,
        total_price:0,
        grand_total:0,
        quantity:1,
        half_day_length:4,
        full_day_length:8,
        depreciation_perice:0,
        isDisabled: true,
        start_time:'',
        end_time:'',
        total_use_of_complimentary:0,
        total_available_complimentary:0,
        selectedComplimentary:null,
        customer_complimentary_id:'',
        discount:0,
        form:{
            checkout_with_xendit : "Y",
            tenant_id : 1,
            location_id : 1,
            customer_id : '',
            contact_id : '',
            emergency_contact_id : null,
            primary_product_id : 1,
            code :'',
            is_inquiry : false,
            has_contract : false,
            is_renewal : false,
            status : "posted",
            renewal_status : "on renewal",
            started_at : '',
            ended_at : '',
            signed_at : '',
            term : "month",
            term_of_payment : 'annually',
            term_notice_period : 3,
            length_of_term : 1,
            deposit:0,
            total_cost : 0,
            total_price : 0,
            total_discount : 0,
            total_tax : 0,
            currency_code : "IDR",
            sales_order_details:[],
            reference_code:'',
            promotion_code:''
        }
        
    };
  },
  created() {
        // setInterval(this.getNow, 0);
    },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  async mounted() {

    const route = useRoute();  
    let router = await route;

    var data = router.query;
    if(data){
        
        if(data.location_id){
            this.location_id = data.location_id;
            this.form.location_id = this.location_id;
        }

        if(data.vendor_id){
            this.vendor_id = data.vendor_id;
        
        } 

        if(data.pricesId){
            this.prices_id = data.pricesId;
        }
        
    
    }
   

    AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant = data;
          this.form.tenant_id =  data.id;
          this.getLocation(data.id, this.location_id);
         
          
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
    );



    if(this.currentUser)
    {

    roomService.getCodeSalesOrder().then(
        (response) => {
        var data = response.data;
        this.form.code = data.data;
        
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
    );
    
        this.form.customer_id =  this.currentUser.user.person.customer.id;
        this.form.contact_id = this.currentUser.user.person.customer.id;
        
    }

    
   

    
    
  },
  methods:{
    getNow() {
        var dateTime = '';
        const today = new Date();
        const time = today.getHours() + ":00";

        if(this.selectedProductPrices.term == 'year' || this.selectedProductPrices.term == 'month' || this.selectedProductPrices.term == 'no term'){
          dateTime = moment().format('YYYY-MM-DD');
        }else{
          dateTime = moment().format('YYYY-MM-DD')+'T'+moment(time, "HH:mm:ss").format('HH:mm');
          
        }
       
        this.form.started_at = dateTime;
        this.form.length_of_term = 1;
        this.changeDate(this.selectedProductPrices.term,'ended_at');
        

    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getLocation(tenantId, locationId){
        productService.getDataLocationById(tenantId,locationId).then(
        (response) => {
            var data = response.data.data;
            this.location = data;
            this.getTrainer(this.tenant.id, this.vendor_id);
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        );
    },
    async getTrainer(tenantId, vendorId){
      productService.getDataTrainerById(tenantId,vendorId).then(
        (response) => {
          var data = response.data.data;
          this.product = data;
         
            var rooms = data.product_prices;

            
            for (let i = 0; i < rooms.length; i++) {
                if (this.prices_id == rooms[i].id ) {
                    this.selectedProductPrices = {
                        "id": rooms[i].id,
                        "vendor_id": rooms[i].vendor_id,
                        "tenant_id" : rooms[i].tenant_id,
                        "product_id" : rooms[i].product_id,
                        "asset_type_id" : rooms[i].asset_type_id,
                        "room_id" : rooms[i].room_id,
                        "area_id" : rooms[i].area_id,
                        "has_quantity" : rooms[i].has_quantity,
                        "has_term" : rooms[i].has_term,
                        "has_complimentary": rooms[i].has_complimentary,
                        "term": rooms[i].term,
                        "item": rooms[i].item,
                        "name": rooms[i].name,
                        "currency_code": rooms[i].currency_code,
                        "default_quantity": rooms[i].default_quantity,
                        "price": rooms[i].price,
                        "desc": rooms[i].desc,
                        "mon": rooms[i].mon,
                        "tue": rooms[i].tue,
                        "wed": rooms[i].wed,
                        "thu": rooms[i].thu,
                        "fri": rooms[i].fri,
                        "sat": rooms[i].sat,
                        "mon_started_time_at": rooms[i].mon_started_time_at,
                        "mon_ended_time_at": rooms[i].mon_ended_time_at,
                        "tue_started_time_at": rooms[i].tue_started_time_at,
                        "tue_ended_time_at": rooms[i].tue_ended_time_at,
                        "wed_started_time_at": rooms[i].wed_started_time_at,
                        "wed_ended_time_at": rooms[i].wed_ended_time_at,
                        "thu_started_time_at": rooms[i].thu_started_time_at,
                        "thu_ended_time_at": rooms[i].thu_ended_time_at,
                        "fri_started_time_at": rooms[i].fri_started_time_at,
                        "fri_ended_time_at": rooms[i].fri_ended_time_at,
                        "sat_started_time_at": rooms[i].sat_started_time_at,
                        "sat_ended_time_at": rooms[i].sat_ended_time_at,
                        
                      };
                }
            }

            this.isLoadingPage = false;
            this.getNow();
            if(this.currentUser){
              this.getComplimentary();
            }
            this.countPrice();
            // this.timeOfProductPrices(this.selectedProductPrices);
            // // setRoom();
            // console.log(this.productPrices);
        
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    changeDate(term, targetIdChange){
      if(this.selectedProductPrices.term == 'year' || this.selectedProductPrices.term == 'month' || this.selectedProductPrices.term == 'no term'){
        this.autoSetupDateTime(this.form.started_at, targetIdChange, term, this.form.length_of_term, true, 'date', false);
      }else{
        this.autoSetupDateTime(this.form.started_at, targetIdChange, term, this.form.length_of_term, true, 'datetime', false);
      }
        
       
    },
    autoSetupDateTime(sourceDate, targetIdChange, term, lengthOfTerm, isAddition=true, format='date', isReturn = false)
    {
        var currentDate = new Date(sourceDate);

        var targetDate = new Date(sourceDate);

        var factor = 1;

        lengthOfTerm = parseInt(lengthOfTerm)

        switch (term) {
          case 'full day':
              factor = this.full_day_length * 60 * 60 * 1000;

              targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
              break;
          case 'half day':
              factor = this.half_day_length * 60 * 60 * 1000;

              targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
              break;
          case 'hour':
              factor = 60 * 60 * 1000;

              targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
              break;
          case 'day':
              factor = 24 * 60 * 60 * 1000;

              targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
              break;
          case 'month':
              var newYear = parseInt(currentDate.getFullYear());
              var newMonth = parseInt(currentDate.getMonth());
              var newDate = currentDate.getDate();

              if (isAddition) {
                  newMonth = newMonth + lengthOfTerm;

                  if (newMonth > 12) {
                      newYear = newYear + (Math.floor(newMonth / 12));
                      newMonth = newMonth % 12;
                  }
              } else {
                  newMonth = newMonth - lengthOfTerm;

                  if (newMonth < 0) {
                      newMonth = newMonth * -1;

                      newYear = newYear - (Math.floor(newMonth / 12));
                      newMonth = newMonth % 12;
                  }
              }

              newDate = (newDate > 30 && (newMonth + 1) % 2 === 0) ? 30 : newDate;

              if (newDate > 28 && (newMonth + 1) == 2) {
                  newDate = (newYear % 4 === 0) ? 29 : 28;
              }

              targetDate = new Date(newYear, newMonth, newDate);

              if (!isReturn) {
                  factor = 24 * 60 * 60 * 1000;

                  targetDate = (isAddition) ? new Date(parseInt(targetDate.getTime() - 1 * factor)) : new Date(parseInt(targetDate.getTime() + 1 * factor));
              }

              break;
          case 'year':
              targetDate = new Date((isAddition) ? parseInt(currentDate.getFullYear()) + lengthOfTerm : parseInt(currentDate.getFullYear()) - lengthOfTerm, parseInt(currentDate.getMonth()), currentDate.getDate());

              if (!isReturn) {
                  factor = 24 * 60 * 60 * 1000;

                  targetDate = (isAddition) ? new Date(parseInt(targetDate.getTime() - 1 * factor)) : new Date(parseInt(targetDate.getTime() + 1 * factor));
              }

              break;
          case 'no term':
              factor = 0;

              targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
              break;
      }

        if (term != 'custom') {
            var yearFormat = targetDate.getFullYear();
            var monthFormat = (targetDate.getMonth() < 9) ? '0'+ parseInt(targetDate.getMonth() + 1) : parseInt(targetDate.getMonth() + 1);
            var dateFormat = (targetDate.getDate() < 10) ? '0'+targetDate.getDate() : targetDate.getDate();
            var hourFormat = (targetDate.getHours() < 10) ? '0'+targetDate.getHours() : targetDate.getHours();
            var minuteFormat = (targetDate.getMinutes() < 10) ? '0'+targetDate.getMinutes() : targetDate.getMinutes()

            if (format == 'date') {
                if (isReturn) {
                    this.form.ended_at = yearFormat+'-'+monthFormat+'-'+dateFormat;
                } else {
                    if(this.targetIdChange == 'start'){
                        this.form.started_at =yearFormat+'-'+monthFormat+'-'+dateFormat;
                    }else{
                      
                        this.form.ended_at =yearFormat+'-'+monthFormat+'-'+dateFormat;
                    }
                   
                }
                
            } else {
                if (isReturn) {
                    this.form.ended_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                } else {
                    if(this.targetIdChange == 'start'){
                        this.form.started_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                    }else{
                        this.form.ended_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                    }
                }
            }

            
        }

        
        
       
    },
    setAvailableComplimentary(){
      if(this.selectedComplimentary != null)
      {
        this.customer_complimentary_id = this.selectedComplimentary.id;
        this.total_available_complimentary = this.selectedComplimentary.quantity;
        this.total_use_of_complimentary = 1;
        this.countPrice();
      }else{
      
        this.customer_complimentary_id = null;
        this.total_available_complimentary = 0;
        this.total_use_of_complimentary = 0;
        this.countPrice();
      }

      
      
    },
    countPrice(promotion = null){
        // console.log('test');
        var price = 0;

        var service_charge = 0;

        var tax =  0;


        var quantity = this.quantity;

        var isCalculate = false;

        var length_of_term = this.form.length_of_term;
        
        if (this.total_use_of_complimentary > length_of_term) {
           this.total_use_of_complimentary = this.form.length_of_term;
        }


        price =  this.selectedProductPrices.price * quantity * (length_of_term - this.total_use_of_complimentary);

        
        tax = (parseFloat(price) - parseFloat(this.discount) + parseFloat(this.service_charge)) * this.tenant.tax_percentage / 100;
        
        if (promotion != null) {
            if (promotion.has_product == 1) {
                if (promotion.product_id == this.productId) {
                    isCalculate = true;
                } else {
                    isCalculate = false;
                }
            } else {
                isCalculate = true;
            }

            if (isCalculate) {
                if(promotion.type == 'percentage') {
                    this.discount = parseFloat(price) * parseFloat(promotion.total) / 100;

                    service_charge -= parseFloat(this.service_charge) * parseFloat(promotion.total) / 100;

                    tax -= parseFloat(tax) * parseFloat(promotion.total) / 100;
                } else {
                  this.discount = promotion.total;
                }
            } else {
                this.discount = 0;

                this.service_charge = parseFloat(price) * this.tenant.service_charge_percentage / 100;
                 tax = (parseFloat(price) - parseFloat(this.discount) + parseFloat(this.service_charge)) * this.tenant.tax_percentage / 100;
            }

           
        }else{
          this.discount = 0;
          if (this.tenant.service_charge_percentage > 0 && service_charge > 0) {
              this.service_charge = (parseFloat(price) - parseFloat(this.discount)) * this.tenant.service_charge_percentage / 100;
              tax = (service_charge * 100) / 100;
          }

          tax = (parseFloat(price) - parseFloat(this.discount) + parseFloat(this.service_charge)) * this.tenant.tax_percentage / 100;
         
        }

       


        var total_price = (parseFloat(price) + parseFloat(this.service_charge));
        var grand_total = (parseFloat(price) - parseFloat(this.discount) + parseFloat(this.service_charge) + parseFloat(tax));



        this.total_price = price;
        this.form.total_price = total_price;
        this.tax = tax;
        this.form.total_tax = tax;
        this.grand_total = grand_total;
        this.form.total_discount =  this.discount;
    },
    // getComplimentary(){
    //   userService.getComplimentary(this.form.customer_id,this.vendor_id,this.form.started_at,this.selectedProductPrices.term).then(
    //       (response) => {
    //         var data = response.data.data;
    //         this.complimentary = data;
    //       }
    //     )
    // },
    roomCheckAvailability(){
        this.isLoadingCheck = true;
        roomService.getRoomAvail(this.selectedProductPrices.room_id,this.form.started_at,this.form.ended_at).then(
        (response) => {
            this.isAvail =  true;
            this.isLoadingCheck = false;
            Swal.fire({
                text: "Ruangan tersedia",
                icon: "success",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            });
            this.addProduct();
        },
        (error) => {
            this.isAvail = false;
            this.isLoadingCheck = false;
           Swal.fire({
                text: "Ruangan tidak tersedia diwaktu atau tanggal yang dipilih.",
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            });
        }
        );

       
    },
    addProduct(){
        
        if(this.currentUser != null){
            roomService.getCodeSalesOrder().then(
                (response) => {
                var data = response.data;
                this.form.code = data.data;
                
                
                },
                (error) => {
                this.content =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
            );
            this.form.sales_order_details.splice(0);

            this.form.primary_product_id = this.product.id;
            if(this.selectedProductPrices != null && this.product.availability == 'periodic'){
                var day = '';
                var started_at = '';
                var ended_at = '';
                const today = new Date();
                day = moment(this.form.started_at).format('dddd').substring(0,3).toLowerCase();
            
                switch(day){
                    case'mon':
                        started_at = this.form.started_at+' '+this.selectedProductPrices.mon_started_time_at;
                        ended_at = this.form.started_at+' '+this.selectedProductPrices.mon_ended_time_at;
                    break;
                    case'tue':
                        started_at = this.form.started_at+' '+this.selectedProductPrices.tue_started_time_at;
                        ended_at = this.form.started_at+' '+this.selectedProductPrices.tue_ended_time_at;
                    break;
                    case'wed':
                        started_at = this.form.started_at+' '+this.selectedProductPrices.wed_started_time_at;
                        ended_at = this.form.started_at+' '+this.selectedProductPrices.wed_ended_time_at;
                    break;
                    case'thu':
                        started_at = this.form.started_at+' '+this.selectedProductPrices.thu_started_time_at;
                        ended_at = this.form.started_at+' '+this.selectedProductPrices.thu_ended_time_at;
                    break;
                    case'fri':
                        started_at = this.form.started_at+' '+this.selectedProductPrices.fri_started_time_at;
                        ended_at = this.form.started_at+' '+this.selectedProductPrices.fri_ended_time_at;
                    break;
                    case'sat':
                        started_at = this.form.started_at+' '+this.selectedProductPrices.sat_started_time_at;
                        ended_at = this.form.started_at+' '+this.selectedProductPrices.sat_ended_time_at;
                    break;
                    case'sun':
                        started_at = this.form.started_at+' '+this.selectedProductPrices.sun_started_time_at;
                        ended_at = this.form.started_at+' '+this.selectedProductPrices.sun_ended_time_at;
                    break;
                }        
                this.form.started_at =  started_at;
                this.form.ended_at =  ended_at;
            
            }
    
            this.form.signed_at = this.form.started_at;
            
            this.form.currency_code = this.product.currency_code;

            if(this.form.term == 'hour' || this.form.term == 'half day' || this.form.term == 'full day'){
              this.form.term = 'day';
            }

            
            this.form.sales_order_details.push({
                "product_id" : this.selectedProductPrices.product_id,
                "vendor_id": this.selectedProductPrices.vendor_id,
                "complimentary_id" : null,
                "customer_complimentary_id" : this.customer_complimentary_id  && this.total_use_of_complimentary > 0 ? this.customer_complimentary_id : null,
                "asset_type_id" : null,
                "asset_id" :null,
                "room_id" :  null,
                "location_id" : this.location_id ? this.location_id : null,
                "name" : this.selectedProductPrices.item ? this.selectedProductPrices.item : this.selectedProductPrices.name,
                "type" : "charged",
                "has_complimentary" :false,
                "has_term" : this.selectedProductPrices.has_term,
                "is_repeated_in_term" :false,
                "term" : this.selectedProductPrices.term,
                "repeated_term" : "no term",
                "started_at" : this.form.started_at,
                "ended_at" : this.form.ended_at,
                "length_of_term" : this.form.length_of_term,
                "has_quantity" : false,
                "quantity" : this.quantity,
                "total_use_of_complimentary" : this.total_use_of_complimentary > 0 ? this.total_use_of_complimentary : 0,
                "cost" : 0,
                "price" : this.selectedProductPrices.price,
                "discount" : this.total_use_of_complimentary > 0 ? this.selectedProductPrices.price : 0,
                "service_charge" : this.service_charge,
                "tax" :(parseFloat(this.selectedProductPrices.price) - parseFloat( this.total_use_of_complimentary > 0 ?  this.selectedProductPrices.price : 0)) * this.tenant.tax_percentage / 100,
                
            });
            
            this.bookProduct();
        }else{
         
            Swal.fire({
                text: "Anda harus login terlebih dahulu.",
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            }).then((result) =>{
            if (result.isConfirmed) {
                this.$router.push({
                  path: '/login',
                  query: {
                    booking_url: this.$route.fullPath,
                  },
                });
              }
          });
        }
       
    },
    bookProduct(){
        console.log(JSON.stringify(this.form));
        this.isLoading = true;
        productService.booking(this.form).then(
        (response) => {
            var data = response;
            this.isAvail =  true;
            this.isLoading = false;
            if(data.total_price == 0){
              Swal.fire({
                text: "Selamat, Anda berhasil Booking, silahkan lakukan pembayaran.",
                icon: "success",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
              }).then((result) =>{
              if (result.isConfirmed) {
                  this.$router.push({
                      path: '/profile',
                    });
                  
                }else{
                  this.$router.push({
                      path: '/profile',
                    });
                }
              });
            }else{
              Swal.fire({
                text: "Selamat, Anda berhasil Booking, silahkan periksa booking pada profil anda.",
                icon: "success",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
              }).then((result) =>{
              if (result.isConfirmed) {
                  if(data.xendit_invoice_url != null){
                    this.$router.push({
                      path: '/',
                    });
                    window.open(data.xendit_invoice_url, '_blank');
                  }else{
                    this.$router.push({
                      path: '/profile',
                    });
                  }
                  
                }else{
                  if(data.xendit_invoice_url != null){
                    this.$router.push({
                      path: '/',
                    });
                    window.open(data.xendit_invoice_url, '_blank');
                  }else{
                    this.$router.push({
                      path: '/profile',
                    });
                  }
                }
              });
            }
        },
        (error) => {
            
            this.isAvail = false;
           Swal.fire({
                text: error.response.data.message,
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            });
        }
        );
    },
    getPromotionCode(code){
      if(this.currentUser)
      {
         
      if (this.form.total_price == 0) {
        Swal.fire({
            text: "Anda tidak bisa menggunakan kode promo, dikarenakan harga total sudah 0.",
            icon: "error",
            customClass: {
            confirmButton: "btn fw-bold btn-danger",
            },
        });
      }else{
        productService.getPromotionCode(code,this.form.customer_id).then(
        (response) => {
            var data = response.data.data;
            this.countPrice(data);
       
        },
        (error) => {
          this.discount = 0;
          this.countPrice();
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        );
      }
       
      }else{
        Swal.fire({
                text: "Anda harus login terlebih dahulu.",
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            }).then((result) =>{
            if (result.isConfirmed) {
                this.$router.push({
                  path: '/login',
                  query: {
                    booking: true,
                  },
                });
              }
          });
      }
    },
    clearPromotionCode(){
      this.form.promotion_code = '';
      this.countPrice();
    },
    
  },
};
</script>