<template>
  <div>
    <div class="mx-auto md:px-36 px-10 w-full py-32">
      <div class="flex flex-row flex-wrap py-4">
        <aside class="w-full sm:w-1/3 md:w-1/4  px-2">
          <div class="sticky top-0 p-2 w-full">
            <div class="antialiased bg-gray-200 min-h-full p-8">
              <div class="flex justify-center">
                <nav id="nav" class="w-56 relative">
                  <span class="absolute h-10 w-full bg-white rounded-lg shadow ease-out transition-transform transition-medium" :style="{ transform: `translateY(calc(100% * ${selected}))` }"></span>
                  <ul class="relative">
                    <li>
                      <button type="button" @click="select(0,'profile')" :aria-selected="selected === 0" class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
                        <svg :class="selected === 0 ? 'text-yellow-600' : 'text-gray-500'" class="h-6 w-6 transition-all ease-out transition-medium" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span :class="selected === 0 ? 'text-yellow-600' : 'text-gray-700'" class="ml-2 text-sm font-medium transition-all ease-out transition-medium">
                          Profile
                        </span>
                      </button>
                    </li>
                    <li>
                      <button type="button" @click="select(1,'password')" :aria-selected="selected === 1" class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
                        <svg :class="selected === 1 ? 'text-yellow-600' : 'text-gray-500'" class="h-6 w-6 transition-all ease-out transition-medium" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                        <span :class="selected === 1 ? 'text-yellow-600' : 'text-gray-700'" class="ml-2 text-sm font-medium transition-all ease-out transition-medium">
                          Change Password
                        </span>
                      </button>
                    </li>
                    <li>
                      <button type="button" @click="select(2,'history')" :aria-selected="selected === 2" class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
                        <svg :class="selected === 2? 'text-yellow-600' : 'text-gray-500'" class="h-6 w-6 transition-all ease-out transition-medium" viewBox="0 0 24 24" fill="currentColor">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13 11.586l2.536 2.535a1 1 0 11-1.415 1.415l-2.828-2.829A.997.997 0 0111 12V8a1 1 0 112 0v3.586z"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1a1 1 0 011 1v1.998c3.918-2.945 9.506-2.635 13.071.93 3.905 3.906 3.905 10.238 0 14.143-3.905 3.905-10.237 3.905-14.142 0A9.972 9.972 0 012 12a1 1 0 112 0 8 8 0 101.777-5.029A1 1 0 014 6.341V2a1 1 0 011-1z"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.293 12.707A.997.997 0 0111 12V8a1 1 0 112 0v3.586l2.536 2.535a1 1 0 11-1.415 1.415l-2.828-2.829zM5.934 1.643A1 1 0 004 2v4.342a1 1 0 001.777.63A8 8 0 114 12v-.001a1 1 0 10-2 0c0 2.558.977 5.119 2.929 7.071 3.905 3.905 10.237 3.905 14.142 0 3.844-3.844 3.904-10.04.18-13.957A10.004 10.004 0 006 3.999V2a.998.998 0 00-.066-.357zM5 2.25z"/>
                        </svg>
                        <span :class="selected === 2 ? 'text-yellow-600' : 'text-gray-700'" class="ml-2 text-sm font-medium transition-all ease-out transition-medium">
                          My Transaction
                        </span>
                      </button>
                    </li>
                   
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </aside>
        
        <main role="main" class="w-full sm:w-2/3 md:w-3/4 pt-1" v-bind:class="selectedTab == 'profile' ? '' :'hidden'">
          <div class="bg-gray-200 rounded-lg  sm:p-6 md:p-8">
            <form  action="#" @submit.prevent="updateProfile" class="mx-auto p-5">
                <h5 class="text-2xl font-bold text-gray">Akun</h5>
                <div class="mt-5 mb-5">
                  <a
                  class=" px-4 py-4 bg-black text-white text-sm font-medium rounded-lg  text-white" data-modal-target="large-modal" data-modal-toggle="large-modal" @click="qrCode()">
                      QR code  <i class="fa fa-qrcode fa-lg" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="mt-10">
                  <label class="font-semibold text-gray">Profil</label>
                  <p class="text-xs font-medium text-gray">Informasi dibawah ini adalah data akun pribadi anda di Xsportainment.</p>
                </div>

                <div class="mt-5">
                  <label class="block text-gray text-md font-bold">Foto</label>
                  <div class="m-auto flex  items-center">
                    <img class="ml-2 w-10 h-10 rounded-full border-2 border-gray-600 mr-3" :src="avatar" alt="Saleh Mir" />
                    <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="previewImage"/>
                    <span class="bg-white hover:bg-700 text-black font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2 cursor-pointer"  @click="onPickFile">
                      Ubah
                    </span>

                    <span class="bg-gray-700 hover:bg-red-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2 cursor-pointer" @click="removeImage">
                      Remove
                    </span>
                  </div>
                </div>

                <div class="mt-10">
                  <div class="grid grid-cols-2 gap-4">
                    <div class="flex grid">
                      <label class="block text-gray text-md font-bold">Nama</label>
                      <input type="text" name="name" placeholder="Input name" v-model="user.name" class="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required/>
                    </div>

                    <div class="flex grid">
                      <label class="block text-gray text-md font-bold">Email</label>
                      <input type="email" name="email" placeholder="Input email" v-model="user.email" class="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required/>
                    </div>
                  </div>
                  <div class="grid grid-cols-2 gap-4 mt-5">
                    <div class="flex grid">
                      <label class="block text-gray text-md font-bold">Gender</label>
                      <select v-model="user.gender"  class="w-full px-4 py-3 rounded-lg b mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required>
                        <option :value="''">Pilih Gender</option>
                        <option :value="'male'">Male</option>
                        <option :value="'female'">Female</option>
                      </select>
                    </div>

                    <div class="flex grid">
                      <label class="block text-gray text-md font-bold">Birthdate</label>
                      <input  name="birthdate" placeholder="Input birthdate" type="date"  v-model="user.birthdate" class="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required/>
                    </div>
                  </div>
                  <div class="flex grid mt-5">
                    <label class="block text-gray text-md font-bold">Telepon</label>
                    <vue-tel-input
                   
                    v-bind="bindProps"
                     v-model="user.phone"
                    class="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black"
                    ></vue-tel-input>
                    <!-- <input type="tel" name="name" placeholder="Input phone" v-model="user.phone" class="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"/> -->
                  </div>
                </div>
                
                <div class="mt-10">
                  <div class="m-auto flex justify-between items-center">
                    <button class="bg-gray-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                      Cancel
                    </button>
                    <button class="bg-white hover:bg-700 text-black font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                      Save
                    </button>
                  </div>
                </div>
            </form>
          </div>
        </main>

        <main role="main" class="w-full sm:w-2/3 md:w-3/4 pt-1" v-bind:class="selectedTab == 'password' ? '' :'hidden'">
          <div class="bg-gray-200 rounded-lg  sm:p-6 md:p-8">
            <form action="#" @submit.prevent="updatePassword" class="mx-auto p-5">
              <h5 class="text-2xl font-bold text-gray">Change Password</h5>
              <div class="mt-10">
                <div class="grid grid-cols-1 mb-5">
                  <div class="flex grid">
                    <label class="block text-gray">Old Password</label>
                    <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
                      <input :type="type" name="password" v-model="user.password"   placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
                      <span @click="showPassword()" class="absolute inset-y-0 right-0 flex items-center pr-3">
                        <i  :class="icon"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="grid gap-4 md:grid-cols-1 lg:grid-cols-2">
                  <div class="flex grid">
                    <label class="block text-gray">New Password</label>
                    <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
                    <input :type="type" name="password" v-model="user.new_password"   placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />           
                      <span @click="showPassword()" class="absolute inset-y-0 right-0 flex items-center pr-3">
                        <i :class="icon"></i>
                      </span>
                    </div>
                  </div>

                  <div class="flex grid">
                    <label class="block text-gray">Password Confirmation</label>
                    <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
                      <input :type="type" name="password" v-model="user.new_password_confirmation"   placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
                      <span @click="showPassword()" class="absolute inset-y-0 right-0 flex items-center pr-3">
                        <i :class="icon"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="mt-10">
                <div class="m-auto flex justify-between items-center">
                  <button class="bg-gray-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                    Cancel
                  </button>
                  <button class="bg-white hover:bg-700 text-black font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </main>

        <main role="main" class="w-full sm:w-2/3 md:w-3/4 pt-1" v-bind:class="selectedTab == 'history' ? '' :'hidden'">
          <div class="bg-gray-200 rounded-lg  sm:p-6 md:p-8  p-5">
            <h5 class="text-2xl font-bold text-gray">My Transaction</h5>
            <div v-if="isLoading" class="flex justify-center items-center">
              <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
            <div class="overflow-y-auto h-3/4" v-else>
              <div v-for="detail in sales_orders" :key="detail.id" >
                <div class="w-full rounded overflow-hidden shadow-lg bg-white mt-5 mb-5">
                  <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div class="px-2 py-4">
                      <div v-if="detail.primary_product != null">
                        <img class="rounded-lg" :src="url+detail.primary_product.picture_path" alt=""> 
                      </div>
                      <div v-else>
                        <img class="rounded-lg" src="../assets/images/fitness-gym.jpg" alt="">
                      </div>
                     
                    </div>
                    <div class="px-4 py-4">
                      <div class="font-bold text-base text-black truncate">{{detail.code}} </div>
                      <div class="font-bold text-base text-black truncate">{{detail.sales_order_details[0].name}}</div>
                      <p class="text-gray-700 text-sm font-semibold">
                        Status pembayaran : 
                        <span v-if="detail.payment_status == 'not paid'">
                          <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-semibold text-red-700 ring-1 ring-inset ring-red-600/10">{{detail.payment_status}}</span>
                        </span>
                        <span v-else-if="detail.payment_status == 'paid'">
                          <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-semibold text-green-700 ring-1 ring-inset ring-green-600/20">{{detail.payment_status}}</span>
                        </span>
                        <span v-else-if="detail.payment_status == 'on invoice'">
                          <span class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-800 ring-1 ring-inset ring-yellow-600/20">{{detail.payment_status}}</span>
                        </span>
                      </p>
                      <p class="text-gray-700 text-sm font-semibold">
                        Nama pemesan: {{detail.customer.person.name}}
                      </p>
                      <p class="text-gray-700 text-sm font-semibold">
                        Mulai tanggal:  {{ date(detail.started_at) }}
                      </p>
                      <p class="text-gray-700 text-sm font-semibold">
                        Sampai tanggal: {{ date(detail.ended_at) }}
                      </p>
                      <div class="mt-2">
                        <div class="font-bold text-base text-black truncate">Total Price: </div>
                        <p class="text-gray-700 text-md font-bold">
                          Rp. {{formatPrice((detail.total_price - detail.total_discount + detail.total_service_charge + detail.total_tax))}}
                        </p>
                      </div>
                     
                     
                    </div>
                  </div>
                  <div class="mt-5 px-2 py-5">
                    <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 justify-center items-center">
                      
                      <button v-if="detail.payment_status == 'not paid' && detail.xendit_invoice_url != null" class=" px-4 py-4 bg-yellow-500 text-white text-sm font-medium rounded-lg" data-modal-target="large-modal" data-modal-toggle="large-modal" @click="payProduct(detail.xendit_invoice_url)">
                        Lakukan pembayaran
                      </button>

                      <button class=" px-4 py-4 bg-blue-500  text-white text-sm font-medium rounded-lg" data-modal-target="large-modal" data-modal-toggle="large-modal" @click="modalDetail(detail.id)">
                        Detail Booking
                      </button>
                    
                    </div>
                   

                   
                  </div>
                </div>
              </div>
            </div>
            <div class="max-w-lg mx-auto  py-5">
              <pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                :has-more-pages="hasMorePages" @pagechanged="showMore">
              </pagination>
            </div>
            
          </div>
        </main>

        
      </div>
    </div>
    <div id="large-modal" tabindex="-1"  v-if="isModal == true"
        class="flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 md:inset-0 h-[calc(100%-1rem)] max-h-full overflow-x-hidden overflow-y-auto"
        :style=" isModal == false ? 'display: none' : ''"
        >
          <div class="relative w-full max-w-4xl max-h-full" >
              <!-- Modal content -->
              
              <div class="flex flex-col items-center justify-center min-h-screen bg-center bg-cover"
                >
                
                <div class="absolute inset-0 z-0"></div>
                <div class="w-full h-full mx-auto z-10 bg-amber-500 rounded-3xl">
                  <div class="self-stretch h-10 flex-col justify-center items-end gap-2.5 flex mt-5 mr-5">
                    <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5  inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="large-modal" @click="isModal = false">
                      <svg aria-hidden="true" class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" style="color:white"></path></svg>
                      <span class="sr-only">Close modal</span>
                  </button>
                  </div>
                  
                  <div class="flex flex-col">
                    <div v-if="isModalLoading" class="flex justify-center items-center">
                      <div
                        class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                          >Loading...</span
                        >
                      </div>
                    </div>
                    <div class="bg-white relative drop-shadow-2xl  rounded-3xl p-4 m-4"  v-else>
                      <div class="flex-none sm:flex">
                        <div class=" relative h-32 w-32   sm:mb-0 mb-3 hidden">
                          <img src="../assets/logo.png" alt="aji" class=" w-32 h-32 object-cover rounded-2xl">
                          <a href="#"
                            class="absolute -right-2 bottom-2   -ml-3  text-white p-1 text-xs bg-green-400 hover:bg-green-500 font-medium tracking-wider rounded-full transition ease-in duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                              class="h-4 w-4">
                              <path
                                d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" >
                              </path>
                            </svg>
                          </a>
                        </div>
                        <div class="flex-auto justify-evenly">
                          <div class="flex items-center justify-between">
                            <div class="flex items-center  my-1">
                              <span class="mr-3 rounded-full bg-white w-8 h-8">
                                  <img src="../assets/logo.png" class="h-8 p-1">
                                </span>
                              <h2 class="font-medium">XSPORTAINMENT</h2>
                            </div>
                            <div class="ml-auto text-blue-800"> </div>
                          </div>
                          <div class="flex items-center justify-between">
                            <div class="flex items-center  my-1">
                              {{ sales_order.code }}
                            </div>
                            <div class="ml-auto text-blue-800"> </div>
                          </div>
                          <div class="border-b border-dashed border-b-2 my-5"></div>
                          <div class="flex items-center">
                            <div class="flex flex-col">
                              <div class="flex-auto text-xs text-gray-400 my-1">
                                <span class="mr-1 ">Mulai Tanggal</span>
                              </div>
                              <!-- <div class="w-full flex-none text-lg text-blue-800 font-bold leading-none">COK</div> -->
                              <div class="text-xs"> {{ date(sales_order.started_at) }}</div>

                            </div>
                            <div class="flex flex-col mx-auto">
                              <!-- <img src="../assets/logo.png" class="w-20 p-1"> -->

                              </div>
                              <div class="flex flex-col ">
                                <div class="flex-auto text-xs text-gray-400 my-1">
                                  <span class="mr-1">Selesai Tanggal</span>
                                </div>
                                <!-- <div class="w-full flex-none text-lg text-blue-800 font-bold leading-none">DXB</div> -->
                                <div class="text-xs">{{ date(sales_order.ended_at) }}</div>

                              </div>
                            </div>
                            <div class="border-b border-dashed border-b-2 my-5 pt-5">
                              <div class="absolute rounded-full w-5 h-5 bg-amber-500 -mt-2 -left-2"></div>
                              <div class="absolute rounded-full w-5 h-5 bg-amber-500 -mt-2 -right-2"></div>
                            </div>
                            <div class="flex items-center mb-5 p-5 text-sm">
                              <div class="flex flex-col">
                                <span class="text-sm">Jangka Waktu</span>
                                <div class="font-semibold">{{ sales_order.length_of_term }} {{sales_order.sales_order_details[0].term == 'hour' ? 'Jam' : sales_order.sales_order_details[0].term == 'year' ? 'Tahun' : sales_order.sales_order_details[0].term == 'day' ? 'Hari' : sales_order.sales_order_details[0].term == 'full day' ? 'Satu Hari' : sales_order.sales_order_details[0].term == 'half day' ? 'Setengah Hari' : sales_order.sales_order_details[0].term == 'no term'? 'session' : 'Bulan'}}</div>

                              </div>
                              <!-- <div class="flex flex-col ml-auto">
                                <span class="text-sm">Customer</span>
                                <div class="font-semibold"> {{ sales_order.customer.person.name }}</div>
                              </div> -->
                            </div>
                            <div class="flex items-center mb-4 px-5">
                              <div class="flex flex-col text-sm">
                                <span class="">Customer</span>
                                <div class="font-semibold">{{ sales_order.customer.person.name }}</div>

                              </div>
                             
                              <div class="flex flex-col text-sm ml-auto">
                                <span class="">Total Price</span>
                                <div class="font-semibold"> Rp.{{formatPrice((sales_order.total_price - sales_order.total_discount + sales_order.total_service_charge + sales_order.total_tax))}}</div>

                              </div>
                            </div>
                            <div class="border-b border-dashed border-b-2 my-5 pt-5">
                              <div class="absolute rounded-full w-5 h-5 bg-amber-500 -mt-2 -left-2"></div>
                              <div class="absolute rounded-full w-5 h-5 bg-amber-500 -mt-2 -right-2"></div>
                            </div>
                            <div class="flex items-center px-5 pt-3">
                              <div class="flex flex-col">
                                <span class="font-semibold text-md">Service</span>
                                <div class="">
                                  <ul class="list-disc text-sm"   v-for="item in sales_order.sales_order_details"
                                  v-bind:key="item.id">
                                    <li > {{ item.name }}</li>
                                  </ul>
                                </div>

                              </div>
                            </div>
                            <div v-if="sales_order.customer_complimentaries.length > 0">
                              <div class="border-b border-dashed border-b-2 my-5 pt-5">
                                <div class="absolute rounded-full w-5 h-5 bg-amber-500 -mt-2 -left-2"></div>
                                <div class="absolute rounded-full w-5 h-5 bg-amber-500 -mt-2 -right-2"></div>
                              </div>
                              <div class="flex items-center px-5 pt-3">
                                <div class="flex flex-col">
                                  <span class="font-semibold text-md">Package</span>
                                  <div class="">
                                    <table class="border-collapse border border-slate-500 text-center w-full">
                                      <thead>
                                        <tr>
                                          <th class="border border-slate-600">Nama</th>
                                          <th class="border border-slate-600 w-40">Kuantiti</th>
                                          <th class="border border-slate-600 w-40">Jumlah yang tersisa</th>
                                          <th class="border border-slate-600 w-50">Masa berlaku package</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr  >
                                          <td class="border border-slate-700 ...">{{ sales_order.customer_complimentaries[0].complimentary.name }}</td>
                                          <td class="border border-slate-700 ...">{{ sales_order.customer_complimentaries[0].quantity }}</td>
                                          <td class="border border-slate-700 ...">{{ sales_order.customer_complimentaries[0].remaining_quantity }}</td>
                                          <td class="border border-slate-700 ...">{{ dates(sales_order.customer_complimentaries[0].started_at) }}</td>
                                        </tr>
                                        
                                      </tbody>
                                    </table>
                                   
                                  </div>
  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
             
          </div>
    </div>
    <div id="large-modal" tabindex="-1"  v-if="isModalQR == true"
        :style=" isModalQR == false ? 'display: none' : ''"
       class="flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 md:inset-0 h-[calc(100%-1rem)] max-h-full overflow-x-hidden overflow-y-auto">
          <div class="relative w-full max-w-4xl max-h-full">
              <!-- Modal content -->
              
              <div class="flex flex-col items-center justify-center min-h-screen bg-center bg-cover"
                >
                
                <div class="absolute inset-0 z-0"></div>
                <div class="w-full h-full mx-auto z-10 bg-amber-500 rounded-3xl">
                  <div class="self-stretch h-10 flex-col justify-center items-end gap-2.5 flex mt-5 mr-5">
                    <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5  inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="large-modal" @click="isModalQR = false">
                      <svg aria-hidden="true" class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" style="color:white"></path></svg>
                      <span class="sr-only">Close modal</span>
                  </button>
                  </div>
                  
                  <div class="flex flex-col">
                    <div v-if="isModalLoading" class="flex justify-center items-center">
                      <div
                        class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                          >Loading...</span
                        >
                      </div>
                    </div>
                      <div class="bg-white relative drop-shadow-2xl  rounded-3xl p-4 m-4" v-else>
                        <div class="flex-none sm:flex">
                            <div class="flex-auto justify-evenly">
                              <div class="flex items-center justify-between">
                                <div class="flex items-center  my-1">
                                  <span class="mr-3 rounded-full bg-white w-8 h-8">
                                      <img src="../assets/logo.png" class="h-8 p-1">
                                    </span>
                                  <h2 class="font-medium">XSPORTAINMENT</h2>
                                </div>
                                <div class="ml-auto text-blue-800"></div>
                              </div>
                              <div class="flex items-center justify-between">
                                <div class="flex items-center  my-1">
                                
                                </div>
                                <div class="ml-auto text-blue-800"> </div>
                              </div>
                              <div class="border-b border-dashed border-b-2 my-5 pt-5">
                                <div class="absolute rounded-full w-5 h-5 bg-amber-500 -mt-2 -left-2"></div>
                                <div class="absolute rounded-full w-5 h-5 bg-amber-500 -mt-2 -right-2"></div>
                              </div>
                              <div class="flex  justify-center items-center">
                                <QRCanvas :options="options"></QRCanvas>
                              </div>
                            </div>

                        </div>
                      
                      </div>
                    </div>
                  </div>

              </div>
             
          </div>
    </div>

  </div>
</template>
<style scoped>
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.transition-fastest {
  transition-duration: 75ms;
}
.transition-faster {
  transition-duration: 100ms;
}
.transition-fast {
  transition-duration: 150ms;
}
.transition-medium {
  transition-duration: 200ms;
}
.transition-slow {
  transition-duration: 300ms;
}
.transition-slower {
  transition-duration: 500ms;
}
.transition-slowest {
  transition-duration: 700ms;
}

.transition-all {
  transition-property: all;
}
.transition-opacity {
  transition-property: opacity;
}
.transition-transform {
  transition-property: transform;
}

.focus-visible.focus-visible\:underline {
  text-decoration: underline;
}

</style>
<script>
const { defineComponent, ref } = require('vue');
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";
import { webUrl } from '../utils/config'
import productService from "@/services/product.service";
import moment from "moment";
const { QRCanvas } = require('qrcanvas-vue');
import Pagination from "@/components/Pagination";



export default {
name: "nav-bar",
components: {
  QRCanvas,
  Pagination,
},
data() {
  return {
      isModal:false,
      isModalQR:false,
      isLoading: true,
      isModalLoading:true,
      isUpdateImage:false,
      url:webUrl,
      selected: 0,
      selectedTab: 'profile',
      avatar: 'https://raw.githubusercontent.com/vuetailwind/storage/master/avatars/saleh-avatar.jpg',
      tenant:{},
      page: 1,
      totalPages: 10,
      total: 10,
      perPage: 8,
      currentPage: 1,
      hasMorePages: true,
      bindProps: {
        mode: "international",
        autoFormat:true,
        defaultCountry: "ID",
        required: false,
        onlyCountries: ["ID"],
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        inputOptions: {
          showDialCode: false,
          placeholder: "Enter a phone number e.g: 820123456789",
        }
      },
      user:{
          tenant_id:'',
          customer_id:'',
          code:'',
          name:'',
          email:'',
          phone:null,
          gender:'',
          birthdate:'',
          city: "-",
          country:"-",
          identity_type:"id_cards",
          identity_number: "-",
          address:"-",
          image:null,
          password:'',
          new_password:'',
          new_password_confirmation: ''
      },
      type: "password",
      showOrHide: "Show",
      icon: "fa fa-eye-slash",
      message: "",
      sales_orders:[],
      sales_order:{},
      options:''

  }
},
setup() {
 
  
},
computed: {
  dataUser() {
    return this.$store.state.auth.user;
  },
},
mounted() {
  this.getTenant();
  this.user.name = this.dataUser.user.name;
  this.user.email = this.dataUser.user.email;
  this.user.code = this.dataUser.user.person.customer.code;
  this.user.customer_id = this.dataUser.user.person.customer.id;
  this.user.tenant_id = this.dataUser.user.person.tenant_id;
  this.avatar = this.url+this.dataUser.user.avatar;
  this.user.image = this.url+this.dataUser.user.avatar;
  this.user.phone = this.dataUser.user.person.phone == 0 ? '' : this.dataUser.user.person.phone;
  this.user.gender = this.dataUser.user.person.gender;
  this.user.birthdate = this.dataUser.user.person.birthdate != null ? this.dataUser.user.person.birthdate : '';
  this.getSalesOrder(this.user.customer_id);
  
},
methods: {
  moment: function (date) {
    return moment(date);
  },
  date: function (date) {
    return moment(date).format("DD MMMM YYYY HH:mm");
  },
  dates: function (date) {
    return moment(date).format("MMMM YYYY");
  },
  formatPrice(value) {
    let val = (value / 1).toFixed(2).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },
  getTenant(){
    AuthService.getDataTenant().then(
        (response) => {
        var data = response.data;
        this.tenant = data;
        
        
        
        },
        (error) => {
        this.content =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
        }
    );
  },
  getSalesOrder(customerID){
    productService.getDataBooking(customerID,this.page).then(
      (response) => {
        var data = response.data.data;
        this.sales_orders = data.data;
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.last_page;
        this.last_page = data.last_page;
        this.isLoading = false;
      
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  select(val,content){
    this.selected = val;
    this.selectedTab = content;
  },
  onPickFile () {
  this.$refs.fileInput.click()
  },
  previewImage: function(event) {
    var input = event.target;
    if (input.files) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.avatar = e.target.result;
        this.user.image= e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
      this.isUpdateImage = true;
    }
  },
  removeImage (){
      this.avatar = 'https://raw.githubusercontent.com/vuetailwind/storage/master/avatars/saleh-avatar.jpg';
      this.user.image = null;
      this.isUpdateImage = false;
  },
  showPassword: function () {
    if (this.type == "password") {
      this.type = "text";
      this.showOrHide = "Hide";
      this.icon = "fa fa-eye";
    } else {
      this.type = "password";
      this.showOrHide = "Show";
      this.icon = "fa fa-eye-slash";
    }
  },
  updateProfile(){
    var mobile = this.user.phone.replace(/ /g,'');
    
    this.user.phone =  mobile.substring(1);
    // console.log(this.user);
    AuthService.updatedProfile(this.user,this.isUpdateImage).then(
        (response) => {
          this.getDataUser();
          Swal.fire({
          title: 'Success, Update your profile.',
          icon: "success",
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: {
              confirmButton: "bg-black hover:bg-black text-white font-bold py-2 px-4 rounded",
          },
          }).then((result) => {
            location.reload();
          })
          
        },
        (error) => {
          this.loading = false;
          Swal.fire({
            text: error.response.data.message,
            icon: "warning",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
        }
      );
  },
  getDataUser(){
    this.$store.dispatch("auth/me").then( response => {
    }, error => {
      Swal.fire({
        text: "Email atau password anda salah",
        icon: "warning",
        customClass: {
          confirmButton: "btn fw-bold btn-danger",
        },
      }).then((result) => {
        this.isLogin = false;
       
        });
    
    });
  },
  updatePassword(){
    AuthService.updatedPassword(this.user).then(
        (response) => {
          console.log(response.message);
          Swal.fire({
          title: 'Success, Update your profile.',
          icon: "success",
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: {
              confirmButton: "bg-black hover:bg-black text-white font-bold py-2 px-4 rounded",
          },
          }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
              // if (result.isConfirmed) {
              //     Swal.fire('Saved!', '', 'success')
              // }
          })
          
        },
        (error) => {
          this.loading = false;
          Swal.fire({
            text: error.response.data.message,
            icon: "warning",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
        }
      );
  },
  modalDetail(Id){
    this.isModal = true;
    productService.getDataBookingDetail(Id).then(
      (response) => {
        var data = response.data.data;
        this.sales_order = data;
        this.isModalLoading = false;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    
  },
  qrCode(){
    const today = new Date();
    var datetime = moment(today).format('YYYY-MM-DD HH:mm:ss')
   
    productService.getQRCode(this.user.customer_id,datetime).then(
      (response) => {
       
        var data = response.data.data;
        
       if(data.length > 0){
          this.isModalQR = true;
          var data_qr =  '';

          for (let i = 0; i < data.length; i++) {
            data_qr  +=  data[i].customer_code +' '+  data[i].start_time + data[i].end_time +  data[i].door_code +  data[i].sales_order;
            
          }
          // console.log(data_qr);
          const options = ref({
            cellSize: 6,
            correctLevel: 'H',
            data:data_qr,
          });

          const image = new Image();
          image.src = this.url +'/'+this.tenant.logo_path;
          image.onload = () => {
            options.value = {
              ...options.value,
              logo: {
                image,
              },
            };
          };
          this.options = options;
          this.isModalLoading = false;
       }else{
        Swal.fire({
            text: 'Mohon maaf anda sedang tidak memiliki layanan yang aktif, silahkan pesan layanan kami',
            icon: "warning",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
       }
        
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    
  },
  payProduct(url){
    window.open(url, '_blank');
  },
  showMore(page){
      this.page = page;
      this.currentPage = page;

      productService.getDataBooking(this.user.customer_id,this.page).then(
      (response) => {
        var data = response.data.data;
        this.sales_orders = data.data;
        
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.last_page;
        this.last_page = data.last_page;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
},
};
</script>