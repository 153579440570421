import { createRouter, createWebHistory } from 'vue-router'
import Login from '../auth/Login.vue';
import Register from '../auth/Register.vue';
import Forgot from '../auth/ForgotPassword.vue';
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs.vue';
import Profile from '../pages/Profile.vue';
import Category from '../pages/Category.vue';
import Product from '../pages/Product.vue';
import BookingProduct from '../pages/BookingProduct.vue';
import DetailProduct from '../pages/DetailProduct.vue';
import DetailProductRoom from '../pages/DetailProductRoom.vue';
import BookingRoom from '../pages/BookingRoom.vue';
import VerifyOTP from '../auth/VerifyOTP.vue';
import ResetPassword from '../auth/ResetPassword.vue';
import SuccessPayment from '../pages/SuccessPayment.vue';
import Trainer from '../pages/Trainer.vue';
import DetailTrainer from '../pages/DetailTrainer.vue';
import BookingTrainer from '../pages/BookingTrainer.vue';
import VerifiedEmail from '../pages/VerifiedEmail.vue';
import Booking from '../pages/Booking.vue';


const routes = [
    {
        path: '/',
        component: Home,
        meta: { layout: 'main-layout' },
    },
    {
        path: '/about-us',
        component: AboutUs,
        meta: { layout: 'main-layout' },
    },
    {
        path: '/profile',
        component: Profile,
        meta: { layout: 'main-layout' },
    },
    {
        name: 'category',
        path: '/category',
        component: Category,
        meta: { layout: 'main-layout' },
    },
    {
        name: 'trainer',
        path: '/trainer',
        component: Trainer,
        meta: { layout: 'main-layout' },
    },
    {
        name: 'product',
        path: '/product',
        component: Product,
        meta: { layout: 'main-layout' },
    },
    {
        name:'product-detail',
        path: '/product-detail',
        component: DetailProduct,
        meta: { layout: 'main-layout' },
    },
    {
        name:'trainer-detail',
        path: '/trainer-detail',
        component: DetailTrainer,
        meta: { layout: 'main-layout' },
    },
    {
        name:'book-product',
        path: '/book-product',
        component: BookingProduct,
        meta: { layout: 'main-layout' },
    },
    {
        name:'detail-product-room',
        path: '/detail-product-room',
        component: DetailProductRoom,
        meta: { layout: 'main-layout' },
    },
    {
        name:'confirmation-payment',
        path: '/confirmation-payment',
        component: SuccessPayment,
        meta: { layout: 'main-layout' },
    },
    {
        name: 'verified_email',
        path: '/verified_email',
        component: VerifiedEmail,
        meta: { layout: 'main-layout' },
    },
    {
        name: 'booking-room',
        path: '/booking-room',
        component: BookingRoom,
        meta: { layout: 'main-layout' },
    },
    {
        name: 'detail-product',
        path: '/detail-product',
        component: Booking,
        meta: { layout: 'main-layout' },
    },
    {
        name: 'booking-trainer',
        path: '/booking-trainer',
        component: BookingTrainer,
        meta: { layout: 'main-layout' },
    },
    {
        path: '/login',
        component: Login,
        meta: { layout: 'auth-layout' },
    },
    {
        path: '/register',
        component: Register,
        meta: { layout: 'auth-layout' },
    },
    {
        path: '/forgot-password',
        component: Forgot,
        meta: { layout: 'auth-layout' },
    },
    {
        name:'verify-otp',
        path: '/verify-otp',
        component: VerifyOTP,
        meta: { layout: 'auth-layout' },
    },
    {
        name:'reset-password',
        path: '/reset-password',
        component: ResetPassword,
        meta: { layout: 'auth-layout' },
    },
    
    
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = [
        '/',
        '/about-us' ,
        '/login',
        '/register',
        '/forgot-password',
        '/service',
        '/product',
        '/product-detail',
        '/detail-product-room',
        '/booking-room',
        '/verify-otp',
        '/reset-password',
        '/book-product',
        '/category',
        '/booking-trainer',
        '/trainer',
        '/trainer-detail',
        '/verified_email',
        '/confirmation-payment',
        '/detail-product'
    ];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page

    if (authRequired && !loggedIn) {
        next({
            path: '/login',
            query: {
                redirect: to.fullPath
            }
        })
    } else {
        next()
    }

});
export default router