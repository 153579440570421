import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                user => {
                    commit('registerSuccess', null);
                        return Promise.resolve(user);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        me({ commit }) {
            return AuthService.getDataProfile().then(
              
                user => {
                   
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        loginSuccess(state, user) {
           
            if(user != null){
                localStorage.setItem('user', JSON.stringify(user));
                state.status.loggedIn = true;
                state.user = user;
            }else{
                state.status.loggedIn = false;
                state.user = null;
            }
        },
        loginFailure(state) {
            const USER = JSON.parse(localStorage.getItem('user'));
            if(USER){
                localStorage.removeItem('user');
            }
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
           
            state.status.loggedIn = false;
            state.user = null;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};