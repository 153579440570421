import axios from 'axios';
import authHeader from './auth-header';
import {
    Customers,
    ForgotPassword,
    ResetPassword,
    UpdateProfile,
    SendOTP,
    CustomerCompliment
} from '../utils/config'
class UserService {
    sendForgotPassword(data) {
        return axios
            .post(ForgotPassword, {
                tenant_id: data.tenant_id,
                email: data.email
            }, { headers: authHeader() })
            .then(response => {
                var data = response.data.data;

                return data;
            });
    }
    sendOTP(data) {
        return axios
            .post(SendOTP, {
                tenant_id: data.tenant_id,
                code: data.code
            }, { headers: authHeader() })
            .then(response => {
                var data = response.data.data;

                return data;
            });
    }
    sendResetPassword(data) {
        return axios
            .post(ResetPassword, {
                tenant_id: data.tenant_id,
                token: data.token,
                email: data.email,
                password: data.password,
                password_confirmation: data.confirmPassword
            }, { headers: authHeader() })
            .then(response => {
                var data = response.data.data;

                return data;
            });
    }

    updateProfile(data,tenant_id) {
        return axios
            .post(UpdateProfile, {
                tenant_id:tenant_id,
                user_id: data.user_id,
                name: data.name,
                phone: data.phone,
                email: data.email,
                identity_type: data.identity_type,
                identity_number: data.identity_number,
                tax_number: data.tax_number,
                country: data.country,
                city: data.city,
                address: data.address,
            }, { headers: authHeader() })
            .then(response => {
                var data = response.data.data;

                return data;
            });
    }

    changePassword(data,tenant_id) {
        return axios
            .post(ResetPassword, {
                tenant_id: tenant_id,
                email: data.email,
                password: data.password,
                password_confirmation: data.confirmPassword
            }, { headers: authHeader() })
            .then(response => {
                var data = response.data.data;

                return data;
            });
    }

    getComplimentary(customerId,productId,startedAt,term){
        return axios.get(CustomerCompliment +customerId+'/'+'complimentaries?product_id=' + productId+'&started_at='+startedAt+'&term='+term, { headers: authHeader() });
    }

    getQRCode(customerId){
        return axios.get(Customers+'/'+customerId+'/'+'access_doors', { headers: authHeader() });
    }
}

export default new UserService();