<template>
    <div class="flex items-center justify-center min-h-screen p-5 bg-gray-100 min-w-screen">
        <div class="max-w-xl p-8 text-center text-gray-800 bg-white shadow-xl lg:max-w-3xl rounded-3xl lg:p-12">
            <h3 class="text-2xl">Payment Done!</h3>
            <div class="flex justify-center">
              <svg viewBox="0 0 24 24" class="text-green-600 w-16 h-16 mx-auto my-6">
                  <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                  </path>
              </svg>
            </div>

            <p>Thank you for completing your secure online payment.</p>
            
            <div class="mt-2">
              <p> Have a great day!  </p>
              <button class="text-white bg-red-700 rounded rounded-full py-4 px-4 mt-2" @click="pushRoute()">GO BACK</button>
              
          </div>
        </div>
    </div>
   
</template>
<script>
export default {
  name: "confirmation-payment-page",
  data() {
    return {     
        
    };
  },
  methods:{
    pushRoute(){
        this.$router.push({
                  path: '/',
                });
    }
   
  }
};
</script>