<template>
    <div class="bg-white pt-10">

      <section class="banner_category mx-auto w-full p-10 lg:p-20 ">
        <div class="rounded-lg p-10 lg:h-[435px]" style="background: linear-gradient(0deg, #F3A323 0%, #F3A323 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.42) 100%);">
          <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 items-center">
            <img src="../assets/images/Kiri.svg" alt="">
            <div class="text-5xl font-semibold text-white text-center">About Us</div>
            <img src="../assets/images/Kanan.svg" alt="">
          </div>
         </div>

         <div class="category_desc mx-auto w-full mt-10">
          <div class="text-3xl font-semibold font-primary">About Us</div>
          <p class="mt-10 text-neutral-700 font-normal text-sm font-primary hidden md:block sm:block text-justify">
            <span class="text-black font-bold">Xsportainment</span> 
            menyediakan fasilitas kelas satu termasuk lapangan badminton modern, meja pingpong, dan mini futsal yang dilengkapi dengan peralatan yang berkualitas. Tak hanya itu, kami juga memberikan akses eksklusif ke fitness gym lengkap dengan peralatan modern, dan menawarkan beragam kelas olahraga seperti Zumba, Yoga, Pound Fit, dan Muay Thai yang dipandu oleh instruktur berpengalaman
          </p>
          <p class="text-black font-normal leading-10">
            Lokasinya yang berada di pusat kota membuat X-Sportainment benar-benar unik dan mudah diakses oleh siapa pun. Selain itu, kami menggunakan teknologi IoT untuk memberi pengalaman yang lebih interaktif, sehingga pemesanan dan penggunaan fasilitas dapat dilakukan dengan mudah berkat platform online kami.
          </p>

          <p class="text-black font-normal leading-10">
            Ketika anda berkunjung ke X-Sportainment, kegiatan olahraga menjadi lebih dari sekadar kegiatan fisik, tetapi menjadi sarana bagi orang-orang untuk berbagi keceriaan, terhubung satu sama lain, dan menciptakan momen yang luar biasa.
          </p>

          <p class="text-black font-normal leading-10">
            X-Sportainment, destinasi terbaik di pusat kota untuk para pecinta hiburan dan olahraga!
          </p>

         </div>
         <div class="mt-5">
          <div class="max-w-sm w-full lg:max-w-full ">
                
            <p class="text-black tracking-tight font-display text-2xl font-bold">Our Story</p>
            <p class="mt-2 text-black font-normal leading-10">
              "Tidak hanya meningkatkan kebugaran fisik, rutin berolahraga juga mengurangi tingkat stres dan meningkatkan produktivitas di tempat kerja." - Penelitian oleh American College of Sports Medicine (ACSM)
            </p>
        </div>
         </div>
      </section>
      
      
    </div>
</template>