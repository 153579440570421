<template>
    <div class="bg-white pt-10">
      <section class="banner_category mx-auto w-full p-10 lg:p-20 ">
        <div class="rounded-lg p-10 lg:h-[435px]" style="background: linear-gradient(0deg, #F3A323 0%, #F3A323 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.42) 100%);">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 items-center">
              <img src="../assets/images/Kiri.svg" alt="">
              <div class="text-5xl font-semibold text-white text-center">{{products.name}}</div>
              <img src="../assets/images/Kanan.svg" alt="">
            
            </div>
        </div>
        <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoading">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 class="text-center text-white text-xl font-semibold font-primary">Loading</h2>
          <p class="w-1/3 text-center text-white mt-2 font-primary">This may take a few seconds, please don't close this page.</p>
         </div>
        <div class="category_desc mx-auto w-full 0">
          <div class="mt-10">
            <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5 pt-10">
              <div class="text-center text-[30px] font-semibold leading-9 text-neutral-700 font-primary">Pilihan {{products.name}}</div>
            </div>
          </div>
          <div class="mt-10 grid gap-4 grid-cols-2 lg:grid-cols-5 justify-center">
            <div  v-for="item in products.product_prices" :key="item.id">
              <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow ">
                <div v-if="item.vendor != null">
                  <img class="rounded-t-lg" :src="webUrl+item.vendor.picture_path" />
                </div>
                <div v-else>
                  <img class="rounded-t-lg" src="../assets/images/trainer.png"  />
                </div>
  
                
                <div class="p-3">
                    <h5 class="mb-2 text-base font-medium text-black">{{item.item}}</h5>
                    <div class="self-stretch text-black text-sm font-semibold " v-if="products.availability == 'periodic'">
                      <span v-if="item.mon == 1 && item.tue == 1 && item.wed == 1 && item.thu == 1 && item.fri == 1 && item.sat == 1 && item.sun == 1">
                        Hari ini
                      </span>
                      <span v-if="item.mon == 1">
                        Senin <br>
                        {{ item.mon_started_time_at }} - {{ item.mon_ended_time_at }}
                      </span>
                      <span v-if="item.tue == 1">
                        Selasa
                        <br>
                        {{ item.tue_started_time_at }} - {{ item.tue_ended_time_at }}
                      </span>
                      <span v-if="item.wed == 1">
                        Rabu
                        <br>
                        {{ item.wed_started_time_at }} - {{ item.wed_ended_time_at }}
                      </span>
                      <span v-if="item.thu == 1">
                        Kamis
                        <br>
                        {{ item.thu_started_time_at }} - {{ item.thu_ended_time_at }}
                      </span>
                      <span v-if="item.fri == 1">
                        Jumat
                        <br>
                        {{ item.fri_started_time_at }} - {{ item.fri_ended_time_at }}
                      </span>
                      <span v-if="item.sat == 1">
                        Sabtu
                        <br>
                        {{ item.sat_started_time_at }} - {{ item.sat_ended_time_at }}
                      </span>
                      <span v-if="item.sun == 1">
                        Minggu
                        <br>
                        {{ item.sun_started_time_at }} - {{ item.sun_ended_time_at }}
                      </span>
                    </div>
  
                    <div class="self-stretch  text-base font-medium leading-normal" style="color: #F97316;" v-if="item.term == 'no term'">Rp.{{ formatPrice(item.price) }} / Session</div>
                    <div class="self-stretch  text-base font-medium leading-normal" style="color: #F97316;" v-else>Rp.{{ formatPrice(item.price) }} / {{item.term}}</div>
  
                    <div class="mt-5">
                      <div class="h-10 bg-amber-500 rounded justify-end items-center gap-2 inline-flex">
                        <button class="lg:w-[200px] self-stretch px-4 py-2.5 justify-center items-center gap-2 flex" @click="pushRoute(item.id)">
                          <div class="text-center text-neutral-700 text-base font-semibold leading-normal"  style="font-size:14px !important">See detail</div>
                         </button>
                      </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
         
        </div>
      </section>
      


     
     </div>
   
</template>
<script>
import AuthService from "@/services/auth.service";
import ApiService from '@/services/api.service';
import productService from "@/services/product.service";
import Pagination from "@/components/Pagination";
import { useRoute } from 'vue-router';
import{ webUrl } from '../utils/config';
export default {
  name: "product-page",
  components: {
    Pagination
  },
  data() {
    return {
        isLoading: true,
        isProductPrices:false,
        tenant_id: "",
        page: 1,
        totalPages: 10,
        total: 10,
        perPage: 8,
        currentPage: 1,
        hasMorePages: true,
        location_id:'',
        product_id:'',
        locations: [],
        categories: [],
        products: {},
        category:{},
        webUrl: webUrl,
        
    };
  },
  computed: {
    indexStart() {
      return (this.currentPage - 1) * this.perPage;
    },
    indexEnd() {
      return this.indexStart + this.perPage;
    },
    paginated() {
      
      return this.products.product_prices.slice(this.indexStart, this.indexEnd);
    },
  },
  async mounted() {
    
    
    const route = useRoute();  
    let router = await route;

    var data = router.query;
    if(data != null ){
      
      if(data.location_id){
          this.location_id = data.location_id;
      
      }
      
    
      if(data.product_id){
        this.product_id = data.product_id;

      }

      AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant_id = data.id;
          this.getLocation(this.tenant_id);
          this.getProduct(this.tenant_id, this.product_id);
          
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      
    
    }
    
    
  },
  methods:{
    getLocation(tenantId){
        ApiService.getDataLocation(tenantId).then(
        (response) => {
            var data = response.data;
            this.locations = data;
            if(this.location_id == ''){
              this.location_id = this.locations[0].id;
            }
           
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    
    getProduct(tenant_id,id){
     
      productService.getDataProductsById(tenant_id,id).then(
        (response) => {
          var data = response.data.data;
        
          this.products = data;
         
          // this.currentPage = data.current_page;
          // this.total = data.total;
          // this.totalPages = data.last_page;
          // this.last_page = data.last_page;
          this.isLoading = false;
          // this.isProductPrices = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    findProduct(){
      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
      (response) => {
        var data = response.data.data;
        this.products = data.data;
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.total;
        this.last_page = data.last_page;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    showMore(page){
      this.page = page;
      this.currentPage = page;

      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
      (response) => {
        var data = response.data.data;
        this.products = data.data;
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.last_page;
        this.last_page = data.last_page;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    pushRoute(Id){
      if(Id != null){
        this.$router.push({
          name: "booking-room",
          query: {
            location_id: this.location_id,
            product_id: this.product_id,
            pricesId: Id
          }
        }).then((response) => {
          location.reload();
        });
        
        
      }

    },
    bookRoom(id){
      var dataRoom = this.productPrices.find(room => room.id === id);
     
      this.$router.push({
        name: "booking-room",
        query: {
          location_id: this.location_id,
          product_id: dataRoom.product_id,
          selectedProductPrices: id,
        }
      }).then((response) => {
        location.reload();
      });
    },
    scrollTop(id){
      var element = document.getElementById(id);
       var top = element.offsetTop;
     
       window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
      
    }
  }
};
</script>