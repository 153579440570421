<template>
    <div class="bg-white pt-10">
      
     
      <div>
        <section class="banner_category mx-auto w-full p-10 lg:p-20 ">
          <div class="rounded-lg p-10 lg:h-[435px]" style="background: linear-gradient(0deg, #F3A323 0%, #F3A323 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.42) 100%);">
              <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 items-center">
                <img src="../assets/images/Kiri.svg" alt="">
                <div class="text-4xl lg:text-5xl font-semibold text-white text-center">{{ category.name }}</div>
                <img src="../assets/images/Kanan.svg" alt="">
              
              </div>
          </div>
          <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoading">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            <h2 class="text-center text-white text-xl font-semibold font-primary">Loading</h2>
            <p class="w-1/3 text-center text-white mt-2 font-primary">This may take a few seconds, please don't close this page.</p>
          </div>
          <div class="category_desc mx-auto w-full mt-10">
            <div class="text-2xl lg:text-3xl font-semibold font-primary">Apa itu {{ category.name }} ?</div>
            <p class="mt-10 text-neutral-700 font-normal text-sm font-primary hidden md:block sm:block text-justify">{{ category.desc }}</p>
  
            <div class="mt-10">
              <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5 pt-10">
                <div class="text-center text-[30px] font-semibold leading-9 text-neutral-700 font-primary">Pilihan {{ category.name }}</div>
              </div>
            </div>
            <div class="mt-10 grid gap-4 grid-cols-2 lg:grid-cols-5 justify-center">
              <div  v-for="item in products" :key="item.id">
                <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow ">
                  <div v-if="item.picture_path != null">
                    <img class="rounded-t-lg" :src="webUrl+item.picture_path"  alt="Sunset in the mountains"/>
                  </div>
                  <div v-else>
                    <img class="rounded-t-lg" src="../assets/images/trainer.png"  alt="Sunset in the mountains"/>
                  </div>
                  <div class="p-3">
                      <h5 class="mb-2 text-base font-medium text-black">{{item.name}}</h5>
                      <div class="self-stretch text-neutral-700 text-base font-semibold leading-normal" v-if="item.term == 'no term'">Rp.{{ formatPrice(item.price) }} / Session</div>
                      <div class="self-stretch text-neutral-700 text-base font-semibold leading-normal" v-else>Rp.{{ formatPrice(item.price) }} / {{ item.term}}</div>
  
                      <div class="mt-5">
                        <div class="h-10 bg-amber-500 rounded justify-end items-center gap-2 inline-flex">
                          <button class="lg:w-[200px] self-stretch px-4 py-2.5 justify-center items-center gap-2 flex" @click="pushRoute(item.id,item.need_to_show_product_prices,item.product_prices.length)">
                            <div class="text-center text-neutral-700 text-base font-semibold leading-normal"  style="font-size:14px !important">See detail</div>
                           </button>
                        </div>
                      </div>
                  </div>
              </div>
              </div>
            </div>
            <div class="max-w-lg mx-auto  py-5">
              <pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                :has-more-pages="hasMorePages" @pagechanged="showMore">
              </pagination>
            </div>
          </div>
          <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-24 font-primary">
            <div class="mx-auto text-left">
              <p class="text-black tracking-tight font-display text-2xl font-bold">FAQ</p>
            </div>
            <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
              <div>
                  <div class="mb-10">
                      <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                          <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                          Proses Booking?
                      </h3>
                      <p class="text-gray-500 dark:text-gray-400">Booking dapat dilakukan secara online dengan membuat akun pada website X-Sportainment kemudian customer melakukan reservasi dan mengisi informasi yang diperlukan.</p>
                  </div>
                
              </div>
              <div>
                <div class="mb-10">                        
                  <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                      <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                      Proses Pembayaran?
                  </h3>
                    <p class="text-gray-500 dark:text-gray-400">Setelah melakukan reservasi, customer akan diarahkan pada pilihan bayar dan mendapatkan kode bayar. Pembayaran dapat melalui Mobile Banking, ATM, atau ecommerce dan retail yang bekerja sama.</p>
                </div>
                  
              </div>

              <div>
                <div class="mb-10">                        
                  <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                      <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                      Larangan Untuk Kegiatan Bersifat Sara:
                  </h3>
                    <p class="text-gray-500 dark:text-gray-400">
                      Pengguna ruangan wajib menjauhi segala bentuk tindakan atau perkataan yang bersifat diskriminatif, merendahkan martabat, atau bersifat pelecehan terhadap suku, agama, ras, dan antar golongan (SARA). 
                    </p>
                </div>
              </div>

              <div>
                <div class="mb-10">                        
                  <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                      <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                      Larangan Acara yang Dilarang oleh Hukum:
                  </h3>
                    <p class="text-gray-500 dark:text-gray-400">
                      Dilarang keras menyelenggarakan acara yang bertentangan dengan hukum yang berlaku. Hal ini mencakup, namun tidak terbatas pada, perjudian ilegal, kegiatan ilegal, atau segala bentuk acara yang melanggar ketentuan hukum yang berlaku. 
                    </p>
                </div>
              </div>

              <div>
                <div class="mb-10">                        
                  <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                      <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                      Larangan Kegiatan Bermuatan politik:
                  </h3>
                    <p class="text-gray-500 dark:text-gray-400">
                      Tidak diperbolehkan Melakukan Acara atau kegiatan yang bermuatan poitik.
                    </p>
                </div>
              </div>

            </div>
          </div>
        </section>

       
        
       
       
      </div>
    </div>
   
</template>
<script>
import AuthService from "@/services/auth.service";
import ApiService from '@/services/api.service';
import productService from "@/services/product.service";
import Pagination from "@/components/Pagination";
import { useRoute } from 'vue-router';
import{ webUrl } from '../utils/config';
export default {
  name: "product-page",
  components: {
    Pagination
  },
  data() {
    return {
        isLoading: true,
        isProductPrices:false,
        tenant_id: "",
        page: 1,
        totalPages: 10,
        total: 10,
        perPage: 8,
        currentPage: 1,
        hasMorePages: true,
        location_id:'',
        category_id:'',
        locations: [],
        categories: [],
        products: [],
        productPrices:[],
        category:{},
        webUrl: webUrl,
        
    };
  },
  computed: {
   
  },
  async mounted() {
    
    
    const route = useRoute();  
    let router = await route;

    var data = router.query;
    if(data){
      
      // if(data.location_id){
      //     this.location_id = this.$CryptoJS.AES.decrypt(
      //     data.location_id,
      //     "123#$%"
      //   ).toString(this.$CryptoJS.enc.Utf8);
      
      // }

    
      if(data.categoryId){
        this.category_id = data.categoryId;

      }

      AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant_id = data.id;
          this.getCategoryById(this.category_id,this.tenant_id);
          this.getLocation(this.tenant_id);
          this.getCategories(this.tenant_id);
          
          
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      
    
    }
    
    
  },
  methods:{
    getCategoryById(Id,tenantId){
      
      productService.getCategoriesById(Id,tenantId).then(
        (response) => {
          var data = response.data.data;
          console.log(data);
          this.category = data;
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getLocation(tenantId){
        ApiService.getDataLocation(tenantId).then(
        (response) => {
            var data = response.data;
            this.locations = data;
            if(this.location_id == ''){
              this.location_id = this.locations[0].id;
            }
           
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    getCategories(tenantId){
      productService.getDataProductCategories(tenantId).then(
        (response) => {
          var data = response.data.data;
          this.categories = data;
          if(this.category_id == ''){
            this.category_id = this.categories[0].id;
          }
         
          this.getProduct();
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getProduct(){
      
      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
        (response) => {
          var data = response.data.data;
          
         

         
          if(data.data.length > 1)
          { 
            // console.log('test');
            this.products = data.data;

            this.currentPage = data.current_page;
            this.total = data.total;
            this.totalPages = data.last_page;
            this.last_page = data.last_page;
            this.isLoading = false;
            this.isProductPrices = false;
          }else{
            this.getRoom(this.tenant_id,data.data[0].id);
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getRoom(tenantId,productId){
      productService.getDataProductsById(tenantId,productId).then(
        (response) => {
          var data = response.data.data;
        
          this.product = data;
          if(this.location_id != null){
            var rooms = data.product_prices;
            this.productPrices.splice(0);
            for (let i = 0; i < rooms.length; i++) {
              if (data.has_room == 1) {
                if (this.location_id == rooms[i].room.location_id) {
                    this.productPrices.push(
                      {
                      "id": rooms[i].id,
                      "tenant_id" : rooms[i].tenant_id,
                      "product_id" : rooms[i].product_id,
                      "asset_type_id" : rooms[i].asset_type_id,
                      "room_id" : rooms[i].room_id,
                      "area_id" : rooms[i].area_id,
                      "has_quantity" : rooms[i].has_quantity,
                      "has_term" : rooms[i].has_term,
                      "has_complimentary": rooms[i].has_complimentary,
                      "term": rooms[i].term,
                      "item": rooms[i].item,
                      "name": rooms[i].name,
                      "currency_code": rooms[i].currency_code,
                      "default_quantity": rooms[i].default_quantity,
                      "price": rooms[i].price,
                      "desc": rooms[i].desc,
                      }
                    );
                  }
              }else{
                this.productPrices.push(
                      {
                      "id": rooms[i].id,
                      "tenant_id" : rooms[i].tenant_id,
                      "product_id" : rooms[i].product_id,
                      "asset_type_id" : rooms[i].asset_type_id,
                      "room_id" : rooms[i].room_id,
                      "area_id" : rooms[i].area_id,
                      "has_quantity" : rooms[i].has_quantity,
                      "has_term" : rooms[i].has_term,
                      "has_complimentary": rooms[i].has_complimentary,
                      "term": rooms[i].term,
                      "item": rooms[i].item,
                      "name": rooms[i].name,
                      "currency_code": rooms[i].currency_code,
                      "default_quantity": rooms[i].default_quantity,
                      "price": rooms[i].price,
                      "desc": rooms[i].desc,
                      }
                    );
              }
              
              
              }
              this.totalPages  = this.productPrices.length / 8;
              this.total = this.productPrices.length;
            
          }else{
            this.productPrices = data.product_prices;
            this.totalPages  = this.productPrices.length / 8;
              this.total = this.productPrices.length;
          }
          this.isProductPrices = true;
          this.isLoading = false;
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    findProduct(){
      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
      (response) => {
        var data = response.data.data;
        this.products = data.data;
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.total;
        this.last_page = data.last_page;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    showMore(page){
      this.page = page;
      this.currentPage = page;

      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
      (response) => {
        var data = response.data.data;
        
        
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.last_page;
        this.last_page = data.last_page;

        this.products = data.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    pushRoute(Id, showPrices, totalPoductPrice){
      // console.log(totalPoductPrice);
      if(showPrices == true && totalPoductPrice > 0){
        this.$router.push({
          name: "product-detail",
          query: {
            location_id: this.location_id,
            product_id: Id
          }
        }).then((response) => {
          location.reload();
        });
        
        
      }else{
        this.$router.push({
          name: "book-product",
          query: {
            location_id: this.location_id,
            product_id: Id
          }
        }).then((response) => {
          location.reload();
        });
      }

    },
    pushRouteDetail(id){
      this.$router.push({
          name: "product",
          query: {
            categoryId: id
          },
        }).then((response) => {
          location.reload();
        });
    },
    bookRoom(id){
      var dataRoom = this.productPrices.find(room => room.id === id);
     
      this.$router.push({
        name: "booking-room",
        query: {
          location_id: this.location_id,
          product_id: dataRoom.product_id,
          selectedProductPrices: id,
        }
      }).then((response) => {
        location.reload();
      });
    },
    scrollTop(id){
      var element = document.getElementById(id);
       var top = element.offsetTop;
     
       window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
      
    }
  }
};
</script>