import { createApp } from 'vue'
import { createHead } from "@vueuse/head"
import ToastPlugin from 'vue-toast-notification';
import VueCryptojs from 'vue-cryptojs'
import router from './routes/routes'
import store from "./store";
import "./assets/css/font-awesome.min.css";
import "./assets/css/style.css";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import VueGtag from "vue-gtag";


global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
import App from './App.vue'

const head = createHead()



createApp(App)
    .use(router)
    .use(store)
    .use(VueCryptojs)
    .use(ToastPlugin, {
        // One of the options
        position: 'top'
    })
    .use(VueGtag, {
        config: { id: "G-7S1FPJG2ZQ" }
      })
    .use(VueTelInput)
    .use(head)
    .mount('#app');
    