<template>
  <div class="flex flex-col md:flex-row h-screen items-center">
    <div class="hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
        <img src="../assets/images/bg-login.png" alt="" class="w-full h-full object-cover"/>
    </div>    
    <div class="w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center bg-white ">
      <div class="w-full h-100">
        <h1 class="text-xl md:text-2xl font-bold leading-tight mt-12 text-center text-black">Login</h1>
        <form class="mt-6" @submit.prevent="userLogin">
          <label class="block text-black">Email</label>
          <input type="email" name="email" v-model="user.email"   placeholder="Input email" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required/>

          <div class="mt-4">
            <label class="block text-black">Password</label>
            <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
              <input :type="type" name="password" v-model="user.password" placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
            
              <span @click="showPassword()" class="absolute inset-y-0 right-0 flex items-center pr-3">
              <i :class="icon"></i>
              </span>
            </div>
          </div>
          <div class="text-right mt-2">
              <router-link :to="{ path: '/forgot-password' }">
                <a href="#" class="text-sm font-semibold text-black hover:text-black focus:text-black">Forgot Password?</a>
              </router-link>
          </div>

          <button type="submit" class="w-full block bg-amber-500 hover:bg-red-400 focus:bg-red-400 text-white font-semibold rounded-lg px-4 py-3 mt-6">Log In</button>
        
        </form>
      <hr class="my-6 border-gray-300 w-full">

      <p class="mt-8 text-red">Butuh akun baru ?  <router-link :to="{ path: '/register', query:{
        booking_url: this.booking_url,

      } }"><a href="#" class="text-black hover:text-blue font-semibold">Buat akun</a></router-link></p>

      <router-link :to="{ path: '/' }"><button class="btn btn-default text-black hover:text-red font-semibold text-center">Kembali ke Beranda</button></router-link>
      </div>
    </div>
</div>
</template>
<script>
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";
export default {
  name: "login-page",
  data() {
    return {
      loading: false,
      booking_url: '',
      user: {
        tenant_id: "",
        email: "",
        password: "",
      },
      type: "password",
      showOrHide: "Show",
      icon: "fa fa-eye-slash",
      message: "",
      is_verify_view: false,
    };
  },
  computed: {
   
  },
  mounted() {
    let data = this.$route.query;
    let email = ''

    if(data.email != null){
      email = this.$CryptoJS.AES.decrypt(
        data.email,
        "123#$%"
      ).toString(this.$CryptoJS.enc.Utf8);

      this.user.email = email
      this.is_verify_view = true
    }
    if(data.booking_url != null)
    {
      this.booking_url = data.booking_url;
    }
    this.getTenant();
   
  },
  methods: {
    getTenant(){
      AuthService.getDataTenant().then(
      (response) => {
        var data = response.data;
        this.user.tenant_id = data.id;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    closeAlert: function () {
      this.alertOpen = false;
    },
    userLogin() {
     
      this.loading = true;
      this.$store.dispatch("auth/login", this.user).then( response => {
      
        if (response.user.person.customer !== null) {
          this.loading = false
          if(this.booking_url != '')
          {
            this.$router.push(this.booking_url);
          }else{
            this.$router.push("/");
          } 
         
          
        } else {
          this.loading = false;
          this.$store.dispatch("auth/logout");
          Swal.fire({
            text: "Mohon maaf user pengguna anda tidak ditemukan, silahkan buat data user terlebih dahulu",
            icon: "warning",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
        }
      }, error => {
        this.loading = false;
        Swal.fire({
          text: "Email atau password anda salah",
          icon: "warning",
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
          },
        });
      });
    },
    showPassword: function () {
      if (this.type == "password") {
        this.type = "text";
        this.showOrHide = "Hide";
        this.icon = "fa fa-eye";
      } else {
        this.type = "password";
        this.showOrHide = "Show";
        this.icon = "fa fa-eye-slash";
      }
    },
    backToLogin(){
      this.is_verify_view = false;
      this.$router.go(this.$route.path)
    }
  },
};
</script>