<template>
    <div>
      <div class="relative isolate pt-16 h-[651px]">
        <div v-if="category.picture_path != null">
          <img :src="webUrl+category.picture_path" class="absolute object-cover  w-full h-full -z-10"/>
        </div>
        <div v-else>
          <img src="../assets/images/bg-kategori.png" class="absolute object-cover  w-full h-full -z-10"/>
        </div>
        <div class="mx-auto max-w-4xl py-32 sm:py-48 lg:py-34 -z-10 px-6 lg:px-8 animate-flip-up animate-once animate-ease-out animate-duration-[1500ms]">
          <div class="text-center font-primary">
            <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Nikmati berbagai kelas favoritmu</h1>
            <p class="mt-6 text-xl leading-8 text-white" >Menjadi lebih FIT dengan kelas berkelompok yang menyenangkan. Kelas tanpa batas ini sudah termasuk dalam paket membership dan Anda dapat memilih kelas sesuai dengan yang Anda inginkan.</p>
            <div class="arrow-container animated fadeInDown cursor-pointer animate-bounce" @click="scrollTop('section_content')">
              <div class="arrow-2">
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="arrow-1 animated hinge infinite zoomIn"></div>
            </div>
          </div>
        </div>
      </div>
      <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoading">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 class="text-center text-white text-xl font-semibold font-primary">Loading</h2>
          <p class="w-1/3 text-center text-white mt-2 font-primary">This may take a few seconds, please don't close this page.</p>
      </div>
      <div v-else>
        <div id="section_content" class="bg-zinc-800 ">
          <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5 pt-36">
            <div class="text-center text-[30px] font-semibold leading-9 text-white font-primary">Pilihan {{ category.name }}</div>
          </div>
         
          <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5">
              <div class="mt-10 grid gap-8 md:grid-cols-2 lg:gap-8 xl:grid-cols-4 ">
  
                  <div  v-for="item in products" :key="item.id">
                    <div class="w-[295px] h-100 px-6 py-8 bg-zinc-800 rounded-tr-lg rounded-bl-lg border border-stone-200 flex-col justify-center items-start gap-6 inline-flex   hover:shadow-[0_4px_0px_rgb(0,0,0)] text-black bg-white ease-out hover:translate-y-1 transition-all font-primary">
                        <div v-if="item.picture_path != null">
                          <img class="w-[244px] h-[163px]" :src="webUrl+item.picture_path"  alt="Sunset in the mountains"/>
                        </div>
                        <div v-else>
                          <img class="w-[244px] h-[163px]" src="../assets/images/trainer.png"  alt="Sunset in the mountains"/>
                        </div>
                        <div class="self-stretch h-30 flex-col justify-center items-start gap-2 flex">
                            <div class="self-stretch text-neutral-100 text-2xl font-semibold ">{{item.name}}</div>
                            <div class="self-stretch text-neutral-100 text-base font-semibold leading-loose" v-if="item.availability == 'periodic'">
                              <span v-if="item.mon == 1 && item.tue == 1 && item.wed == 1 && item.thu == 1 && item.fri == 1 && item.sat == 1 && item.sun == 1">
                                Hari ini
                              </span>
                              <span v-else-if="item.mon == 1">
                                Senin 
                              </span>
                              <span v-else-if="item.tue == 1">
                                Selasa
                              </span>
                              <span v-else-if="item.wed == 1">
                                Rabu
                              </span>
                              <span v-else-if="item.thu == 1">
                                Kamis
                              </span>
                              <span v-else-if="item.fri == 1">
                                Jumat
                              </span>
                              <span v-else-if="item.sat == 1">
                                Sabtu
                              </span>
                              <span v-else>
                                Minggu
                              </span>
                            </div>
                            <div v-if="item.started_time_at && item.ended_time_at">
                              <div class="self-stretch text-neutral-100 text-base font-normal leading-normal">{{ item.started_time_at }} - {{ item.ended_time_at }}</div>
                            </div>
                            <div class="self-stretch text-neutral-100 text-base font-semibold leading-normal" v-if="item.term == 'no term'">Rp.{{ formatPrice(item.price) }} / Session</div>
                            <div class="self-stretch text-neutral-100 text-base font-semibold leading-normal" v-else>Rp.{{ formatPrice(item.price) }} / {{ item.term}}</div>
                        </div>
                        <div class="self-stretch h-10 flex-col justify-center items-end gap-2.5 flex mt-5">
                            <div class="h-10 bg-amber-500 rounded justify-end items-center gap-2 inline-flex">
                              <button class="w-[150px] self-stretch px-4 py-2.5 justify-center items-center gap-2 flex" @click="pushRoute(item.id,item.need_to_show_product_prices,item.product_prices.length)">
                                <div class="text-center text-neutral-100 text-base font-semibold leading-normal">See detail</div>
                              </button>
                            </div>
                        </div>
                    </div>
                   
                  </div>
                
  
                  
              </div>
          </div>
          <div class="max-w-lg mx-auto  py-5">
            <pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
              :has-more-pages="hasMorePages" @pagechanged="showMore">
            </pagination>
          </div>

          <!-- Faq -->
          
        </div>
        <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-24 font-primary">
          <div class="mx-auto text-left">
            <p class="text-black tracking-tight font-display text-2xl font-bold">FAQ</p>
          </div>
          <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
            <div>
                <div class="mb-10">
                    <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                        <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                        Proses Booking?
                    </h3>
                    <p class="text-gray-500 dark:text-gray-400">Booking dapat dilakukan secara online dengan membuat akun pada website X-Sportainment kemudian customer melakukan reservasi dan mengisi informasi yang diperlukan.</p>
                </div>
              
            </div>
            <div>
              <div class="mb-10">                        
                <h3 class="flex items-center mb-4 text-lg font-medium text-black ">
                    <img src="../assets/images/icon_setting.png" alt="" class="mr-2">
                    Proses Pembayaran?
                </h3>
                  <p class="text-gray-500 dark:text-gray-400">Setelah melakukan reservasi, customer akan diarahkan pada pilihan bayar dan mendapatkan kode bayar. Pembayaran dapat melalui Mobile Banking, ATM, atau ecommerce dan retail yang bekerja sama.</p>
              </div>
                
            </div>
          </div>
        </div>
      </div>
    </div>
   
</template>
<script>
import AuthService from "@/services/auth.service";
import ApiService from '@/services/api.service';
import productService from "@/services/product.service";
import Pagination from "@/components/Pagination";
import { useRoute } from 'vue-router';
import{ webUrl } from '../utils/config';
export default {
  name: "product-page",
  components: {
    Pagination
  },
  data() {
    return {
        isLoading: true,
        isProductPrices:false,
        tenant_id: "",
        page: 1,
        totalPages: 10,
        total: 10,
        perPage: 8,
        currentPage: 1,
        hasMorePages: true,
        location_id:'',
        category_id:'',
        locations: [],
        categories: [],
        products: [],
        category:{},
        webUrl: webUrl,
        
    };
  },
  computed: {
   
  },
  async mounted() {
    
    
    const route = useRoute();  
    let router = await route;

    var data = router.query;
    if(data){
      
      // if(data.location_id){
      //     this.location_id = this.$CryptoJS.AES.decrypt(
      //     data.location_id,
      //     "123#$%"
      //   ).toString(this.$CryptoJS.enc.Utf8);
      
      // }

    
      if(data.categoryId){
        this.category_id = data.categoryId;

      }

      AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant_id = data.id;
          this.getCategoryById(this.category_id,this.tenant_id);
          this.getLocation(this.tenant_id);
          this.getCategories(this.tenant_id);
          
          
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      
    
    }
    
    
  },
  methods:{
    getCategoryById(Id,tenantId){
      
      productService.getCategoriesById(Id,tenantId).then(
        (response) => {
          var data = response.data.data;
          console.log(data);
          this.category = data;
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getLocation(tenantId){
        ApiService.getDataLocation(tenantId).then(
        (response) => {
            var data = response.data;
            this.locations = data;
            if(this.location_id == ''){
              this.location_id = this.locations[0].id;
            }
           
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    getCategories(tenantId){
      productService.getDataProductCategories(tenantId).then(
        (response) => {
          var data = response.data.data;
          this.categories = data;
          if(this.category_id == ''){
            this.category_id = this.categories[0].id;
          }
         
          this.getProduct();
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getProduct(){
      
      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
        (response) => {
          var data = response.data.data;
          
          this.products = data.data;
          this.currentPage = data.current_page;
          this.total = data.total;
          this.totalPages = data.last_page;
          this.last_page = data.last_page;
          this.isLoading = false;
          this.isProductPrices = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    findProduct(){
      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
      (response) => {
        var data = response.data.data;
        this.products = data.data;
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.total;
        this.last_page = data.last_page;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    showMore(page){
      this.page = page;
      this.currentPage = page;

      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
      (response) => {
        var data = response.data.data;
        this.products = data.data;
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.last_page;
        this.last_page = data.last_page;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    pushRoute(Id, showPrices, totalPoductPrice){
      // console.log(totalPoductPrice);
      if(showPrices == true && totalPoductPrice > 0){
        this.$router.push({
          name: "product-detail",
          query: {
            location_id: this.location_id,
            product_id: Id
          }
        }).then((response) => {
          location.reload();
        });
        
        
      }else{
        this.$router.push({
          name: "book-product",
          query: {
            location_id: this.location_id,
            product_id: Id
          }
        }).then((response) => {
          location.reload();
        });
      }

    },
    bookRoom(id){
      var dataRoom = this.productPrices.find(room => room.id === id);
     
      this.$router.push({
        name: "booking-room",
        query: {
          location_id: this.location_id,
          product_id: dataRoom.product_id,
          selectedProductPrices: id,
        }
      }).then((response) => {
        location.reload();
      });
    },
    scrollTop(id){
      var element = document.getElementById(id);
       var top = element.offsetTop;
     
       window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
      
    }
  }
};
</script>