<template>
  <div class="bg-white">
    <Carousel class="text-center pt-20">
      <Slide v-for="slide in 1" :key="slide">
        <div
        class="text-white py-44  bg-cover bg-no-repeat bg-center w-full bg-img"
      
      >
        <div class="lg:px-60 justify-center">
          <p
            class="font-semibold text-3xl lg:text-6xl"
            style="color: white"
          >
          Get Active, Stay Fit, and Enjoy Moments at X-Sportainment!
          </p>
        
        </div>
      </div>
      </Slide>
  
      <template #addons>
        <Navigation class="rounded-full bg-yellow-500"/>
        <Pagination />
      </template>
    </Carousel>
    
    <section class="lapangan mx-auto w-full p-10 lg:p-20">
      <div class="text-3xl font-semibold font-primary">Lapangan</div>
      <p class="mt-10 text-neutral-700 font-normal text-sm font-primary hidden md:block sm:block justify-center">{{lapangan.desc}}</p>
      <div class="mt-10">
        <Carousel v-bind="settings" :breakpoints="breakpoints" :transition="500" :autoplay="2000">
            <Slide v-for="slide in lapangan.products" :key="slide">

              <div class="relative max-w-xl mx-auto" @click="goToProduct(slide.id,slide.need_to_show_product_prices,false)">
                <img class=" w-full object-cover rounded-2xl w-[235px] h-[282px]" :src="url + slide.picture_path">
                <div class="absolute flex bottom-5 left-5">
                  <div class="text-xl font-semibold font-primary text-white drop-shadow-md">{{ slide.name }}</div>
                </div>
              </div>
            
            </Slide>
            <template #addons>
              <Navigation class="rounded-full bg-yellow-500"/>
            </template>
          </Carousel>
      </div>
      <div class="banner_tagline mt-5">
        <img src="../assets/images/banner_tagline.png" alt="" class="h-auto max-w-full"> 
      </div>

      <div class="kelas mx-auto w-full mt-10">
        <div class="text-3xl font-semibold font-primary">Kelas</div>
        <p class="mt-10 text-neutral-700 font-normal text-sm font-primary hidden md:block sm:block justify-center">{{ kelas.desc }}</p>
        <div class="mt-10">
          <Carousel v-bind="settings" :breakpoints="breakpoints" :transition="500" :autoplay="2000">
              <Slide v-for="slide in kelas.products" :key="slide">
  
                <div class="relative max-w-xl mx-auto" @click="goToProduct(slide.id,slide.need_to_show_product_prices,true)">
                  <img class=" w-full object-cover rounded-2xl w-[235px] h-[282px]" :src="url + slide.picture_path">
                  <div class="absolute flex bottom-5 left-5">
                    <div class="text-xl font-semibold font-primary text-white drop-shadow-md">{{ slide.name }}</div>
                  </div>
                </div>
              
              </Slide>
              <template #addons>
                <Navigation class="rounded-full bg-yellow-500"/>
              </template>
            </Carousel>
        </div>
      </div>
      <div class="banner_trainer  mt-5">
        <img src="../assets/images/banner_trainer.png" alt="" class="h-auto max-w-full">
      </div>
      <div class="kelas mx-auto w-full mt-10">
        <div class="text-3xl font-semibold font-primary">Trainer</div>
        <p class="mt-10 text-neutral-700 font-normal text-sm font-primary hidden md:block sm:block justify-center">X-Sportainment memiliki Trainer yang telah berpengalaman dibidang nya. Kami yakin dengan trainer yang memiliki kapasitas dan keahlian anda dapat memperoleh manfaat untuk kesehatan, kebugaran serta meningkatkan kebahagian jasmani & rohani anda.</p>
        <div class="mt-10">
          <Carousel v-bind="settings" :breakpoints="breakpoints" :transition="500" :autoplay="2000">
              <Slide v-for="slide in trainers" :key="slide">
  
                <div class="carousel__item relative max-w-xl mx-auto">
                  <img class="w-full object-cover rounded-2xl items-center" :src="url + slide.picture_path">
                  <div class="absolute flex bottom-5 left-5">
                    <div class="text-xl font-semibold font-primary text-white drop-shadow-md">{{ slide.person.name }}</div>
                  </div>
                </div>
              
              </Slide>
              <template #addons>
                <Navigation class="rounded-full bg-yellow-500"/>
              </template>
            </Carousel>
        </div>
      </div>
      <div class="banner_membership  p-10 mt-5 h-auto">
        <img class="h-auto max-w-full" src="../assets/images/banner_membership.png" alt="" @click="goToPage()">
      </div>
  
      <div class="membership mx-auto w-full mt-10">
        <div class="text-2xl lg:text-3xl font-semibold font-primary text-center">Apa itu Membership ?</div>
        <div class="pl-20 pr-20">
          <p class="text-center mt-10 text-neutral-700 font-normal text-sm font-primary hidden md:block sm:block justify-center">{{ membership.desc }}</p>
        </div>
        <div class="flex flex-col items-center mt-10">
          <div class="h-10 bg-amber-500 rounded text-center items-center gap-2 inline-flex">
            <button class="w-[200px] self-stretch px-4 py-2.5 justify-center items-center gap-2 flex" @click="goToPage()">
              <div class="text-center text-neutral-700 text-base font-semibold leading-normal"  style="font-size:14px !important">Lihat detail</div>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
  .bg-img {
    background-image: linear-gradient(to left, rgba(227,153,35, 0.5), rgba(227,153,35, 0.5)), url('../assets/images/bg-hero-slide.png');
  }
  .carousel__slide {
    padding: 5px;
  }
</style>
<script>
import ApiService from '@/services/api.service';
import AuthService from "@/services/auth.service";
import productService from "@/services/product.service";
import{ webUrl } from '../utils/config';
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  name: "Home",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    
    
    return {
     url: webUrl,
     location_id:'',
     tenant:{},
     lapangan:{},
     kelas:{},
     trainers:[],
     membership:{},
     settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
    };
  },
  computed: {

  },
  mounted() {
    AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant = data;
          this.getLocation();
          this.getCategoryLapangan();
         this.getCategoryKelas();
         this.getTrainer();
         this.getCategoryMembership();
          
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    
  },
  methods: {
    getLocation(){
        ApiService.getDataLocation(this.tenant.id).then(
        (response) => {
            var data = response.data;
            this.locations = data;
            if(this.location_id == ''){
              this.location_id = this.locations[0].id;
            }
           
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    getCategoryLapangan(){
      productService.getDataProductsByName(this.tenant.id,'lapangan').then(
        (response) => {
          var data = response.data.data;
          this.lapangan = data[0];
          // console.log(data[0].products);
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getCategoryKelas(){
      productService.getDataProductsByName(this.tenant.id,'kelas').then(
        (response) => {
          var data = response.data.data;
          this.kelas = data[0];
          // console.log(data[0].products);
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getTrainer(){
      productService.getTrainer(this.tenant.id).then(
        (response) => {
          var data = response.data.data;

            this.trainers = data;
            this.isLoading = false;
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getCategoryMembership(){
      productService.getDataProductsByName(this.tenant.id,'membership').then(
        (response) => {
          var data = response.data.data;
          this.membership = data[0];
          // console.log(data[0].products);
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    goToProduct(Id, showPrices, isProductPrices){
      // console.log(totalPoductPrice);
      if(showPrices == true && isProductPrices == true){
        this.$router.push({
          name: "product-detail",
          query: {
            location_id: this.location_id,
            product_id: Id
          }
        }).then((response) => {
          location.reload();
        });
        
        
      }else{
        this.$router.push({
          name: "book-product",
          query: {
            location_id: this.location_id,
            product_id: Id
          }
        }).then((response) => {
          location.reload();
        });
      }

    },
    goToPage(){
      if(this.membership != null){
        this.$router.push({
          name: "category",
          query: {
            categoryId: this.membership.id
          },
        }).then((response) => {
          location.reload();
        });
      }
    }
    
  },
};
</script>