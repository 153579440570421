let baseUrl = '';

// baseUrl = 'http://178.128.126.205:3026/api/';

// baseUrl = 'http://178.128.126.205:3024/api/';

baseUrl = 'https://xsport.telpro.co.id/api/';
// export const webUrl = 'http://178.128.126.205:3026';

export const webUrl = 'https://xsport.telpro.co.id';
export const Tenant = baseUrl + 'get_tenant?code=XSPORT';

export const Login = baseUrl + 'login';
export const Register = baseUrl + 'register';
export const Logout = baseUrl + 'logout';
export const ForgotPassword = baseUrl + 'request_otp';
export const SendOTP = baseUrl + 'verify_otp';
export const ResetPassword = baseUrl + 'reset_password';

export const Profile = baseUrl + 'me';
export const UpdateProfile = baseUrl + 'update_profile';
export const updatedPassword = baseUrl + 'update_password';


export const Code = baseUrl + 'customers-get-code';
export const SalesOrderCode = baseUrl + 'sales_orders-get-code';
export const Customers = baseUrl + 'customers';
export const Location = baseUrl + 'locations-get-without-auth';
export const Locations = baseUrl + 'locations';
export const Product = baseUrl + 'products';
export const ProductCategories = baseUrl + 'product_categories';
export const Rooms = baseUrl + 'rooms';
export const RoomAvailable = baseUrl + 'rooms-check-availability';
export const Trademark = baseUrl + 'trademarks';
export const Referral = baseUrl + 'referrals';
export const Employee = baseUrl + 'employees';
export const Agent = baseUrl + 'agents';
export const Complimentary = baseUrl + 'complimentaries';
export const Invoices = baseUrl + 'invoices';
export const BankAccounts = baseUrl + 'bank_accounts';
export const SalesOrders = baseUrl + 'sales_orders';
export const ServiceUsage = baseUrl + 'service_usage';
export const CustomerCompliment = baseUrl + 'customers/';
export const Promotion = baseUrl + 'promotions-get-by-code';
export const Trainer = baseUrl + 'vendors-get-without-auth';
export const Trainers = baseUrl + 'vendors';
export const Events = baseUrl + 'events-without-auth';
export const Calendar = baseUrl + 'sales_order_details/calender-without-auth';